import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PosService } from 'src/services/pos.services';
import { NotificationService } from 'src/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/config/app.config';
import { ServerStreamResponseOptions } from 'http2';

declare function pos_pay_test(): any;

@Component({
  selector: 'app-home',
  templateUrl: './goodbye.component.html',
})
export class GoodbyeComponent implements OnInit
{
  public myGaestekonto: GaestekontoViewModel;
  //public myPament: PaymentViewModel;
  private loginReq: LoginRequestModel;
  private LoginCode: string;
  private gkid: string;
  private resourcePath: string;
  private paymentSuccess: boolean;
  private paymentWaiting: boolean;
  private ErrorMessage: string;
  private SuccessMessage: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private posService: PosService, private notification: NotificationService, private _config: AppConfig, public translate: TranslateService) {

    
    console.log("goodbye init ...");
    this.init();
    this.setText();
    //if (!posService.validLogin) {
    //  console.log("no valid Login found -> navigate to logon page");
    //  this.router.navigate(["login"]);
    //}


  }

  ngOnInit() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.activatedRoute.queryParams.subscribe(params => {

      console.log("goodbye loaded ...");

      this.resourcePath = params['resourcePath'];
      this.LoginCode = params['idx'];
      this.gkid = params['gkid'];

      //console.log(this.resourcePath);
      //console.log(this.LoginCode);
      //console.log(this.gkid);

      this.paymentState();
      this._login();
    });
  }

  init()
  {
    this.paymentWaiting = true;
    this.paymentSuccess = false;
    this.myGaestekonto = {} as GaestekontoViewModel;
    this.loginReq = {} as LoginRequestModel;
    this.posService.login = {} as UserInfoModel;
    this.posService.login.displayName = "unknown";
  }

  setText()
  {
    const ErrorMessage_key = 'Goodbye.ErrorMessage';
    this.translate.get(ErrorMessage_key).subscribe((res) => {

      if (res === ErrorMessage_key)
        this.ErrorMessage = "Kein " + ErrorMessage_key + " Text gefunden";
      else
        this.ErrorMessage = res;
    });

    const SuccessMessage_key = 'Goodbye.SuccessMessage';
    this.translate.get(SuccessMessage_key).subscribe((res) => {

      if (res === SuccessMessage_key)
        this.SuccessMessage = "Kein " + SuccessMessage_key + " Text gefunden";
      else
        this.SuccessMessage = res;
    });
  }

  _getSuccessMessage(): string { return this.SuccessMessage; }
  _getErrorMessage(): string { return this.ErrorMessage; }


  _changeLan(lang: string) {
    this.translate.use(lang);
    this._config.useLang(lang);
    this.setText();
  }

  _login() {

    if (this.posService.validLogin === true) {
      this.notification.success("Login gefunden.");
    } else {

      this.loginReq.UserID = this.LoginCode;

      this.posService.Login(this.loginReq)
        .subscribe((res: any) => {

          if (res.success) {

            this.posService.login = res;
            this.posService.validLogin = true;
            this.loadGK();
          } else
          {
            this.posService.validLogin = false;
            this.notification.error(res.errorMessage);
          }
        });
    }
  }


  paymentState() {

    //console.log("get payment state >>");

    if (this.posService.login) {
      const request = {} as RequestPaymentViewModel;
      request.resourcePath = this.resourcePath;
      request.gkid = Number(this.gkid);

      this.posService.getPaymentState(request)
        .subscribe((res: any) => {
          //this.myPament = res;
          //console.log(res);

          if (res.success) {
            this.paymentWaiting = false;
            this.paymentSuccess = true;
            this.notification.success(res.successMessage);
            this.openBill();
            
          } else
          {
            this.paymentWaiting = false;
            this.paymentSuccess = false;
            //this.notification.error(res.errorMessage);
          }
        });
    }
  }


  loadGK()
  {
    if (this.posService.login)
    {
      const request = {} as RequestGaestekontoViewModel;
      request.gkid = this.posService.login.gkid;

      this.posService.getGeastekonto(request)
        .subscribe((res: any) => {
          this.myGaestekonto = res;
          //console.log(res);
        });
    }
  }


  navhome() {
    this.router.navigate(["login"]);
  }

  logout()
  {
    this.posService.Logout();
    this.router.navigate(["login"]);
  }

  openBill() {
    const request = {} as RequestGaestekontoViewModel;
    request.gkid = this.posService.login.gkid;

    this.posService.getPDF(request)
      .subscribe((res: any) => {
        this.HandleBase64(res.bytes, "application/pdf", res.fileName);
        return;
      });
  }

  HandleBase64(data, contentType, fileName) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++)
      byteNumbers[i] = byteCharacters.charCodeAt(i);

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    if (contentType === "audio/wav") {
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }
    else {
      const blobURL = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.click();
    }
  }

}
