import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NotificationService {

  private SuccessMessageSource = new Subject<string>();
  successMessage$ = this.SuccessMessageSource.asObservable();

  private WarningMessageSource = new Subject<string>();
  warningMessage$ = this.WarningMessageSource.asObservable();

  private ErrorMessageSource = new Subject<string>();
  errorMessage$ = this.ErrorMessageSource.asObservable();

  success(message: string) {
    console.log(message);
    this.SuccessMessageSource.next(message);
  }

  warning(message: string) {
    console.warn(message);
    this.WarningMessageSource.next(message);
  }

  error(message: string) {
    console.error(message);
    this.ErrorMessageSource.next(message);
  }
}
