import { Component, ViewEncapsulation } from '@angular/core';
import { Router, RouteReuseStrategy } from "@angular/router";
import { NotificationService } from 'src/services/notification.service';
import { PosService } from 'src/services/pos.services';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { element } from 'protractor';

@Component({
  selector: 'app-home',
  templateUrl: './touch_kassa.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./touch_kassa.css']
})


export class TouchKassaComponent {

  public hasSelectedPOSConfig: boolean;
  public posname: string;
  public PosConfigList: PosConfig[];
  public myPosConfig: PosConfig;
  public selectableSettings: SelectableSettings;
  public errorReceived: boolean;
  public modeBestellungen: boolean;

  public artikel: Artikel[];
  public gaeste: GastDisplay[];
  public bestellungen: Bestellung[];
  public bestelldetailprintlist: BestellungDetailViewModel[];
  public filtergaeste: GastDisplay[];
  public searchString: string;
  public showAllBestellungen: boolean;
  public hasSelectedGast: boolean;
  public hasSelectedArtikel: boolean;
  
  public hasSelectedBestellung: boolean;
  
  public selectedGast: GastDisplay;
  public selectedBestellung: Bestellung;
  public myBasket: Basket;
  
  
  public lastkeydown1: number;
  public userData: any[];
  public newstyle: boolean;
  //public requestBacklisteDate: Date;
  public Backliste: Bestellung;

  public requestDate: Date;
  public heute: boolean;
  public morgen: boolean;
  public alle: boolean;

  public showBestellungCol: string;

  public cfgGKDisabled: boolean;
  public cfgKassaDisabled: boolean;
  public cfgBestellungDisabled: boolean;
  public cfgBestellungRrepeatable: boolean;

  public hasMoreArtikelGruppen: boolean;
  public btnGKDisabled: boolean;
  public btnKassaDisabled: boolean;
  public btnBestellungDisabled: boolean;
  public btnPrintDisabled: boolean;

  public artikelGruppen: POSGroups[];

  public steps: any = { hour: 1, minute: 15 };

  public _abholungAm: Date;
  public _min: Date;
  public _max: Date;
  //.............
  public btnActionDisabledSendBestellungToGK: boolean;
  public btnActionDisabledSendBestellungToKassa: boolean;

  public btnDisabledSendBestellungToGK: boolean;
  public btnDisabledSendBestellungToKassa: boolean;

  //Touch Kassa
  public currentartikel: Artikel[];
  public currentPOSGroups: POSGroups;

  constructor(private router: Router, private notification: NotificationService, private posService: PosService,private sanitizer: DomSanitizer) {
    this._Init();
    this.setPOSSettings();
    ////console.log(this.posService);

    if (!this.posService.login) {
      //console.log("No POS Login found - navigate to Login");
      this.router.navigate(["login"]);
    }

  }

  _Init() {

    //...Kassa....
    this.currentartikel = new Array<Artikel>();
    this.currentPOSGroups = {} as POSGroups;
    //............

    this.errorReceived = false;

    this.showBestellungCol = "";
    this.PosConfigList = new Array<PosConfig>();
    this.bestellungen = new Array<Bestellung>();
    this.myBasket = {} as Basket; 
    this.myPosConfig = {} as PosConfig;
    this.Backliste = {} as Bestellung;
    this.myBasket.artikel = new Array<Artikel>();
    this.artikelGruppen = new Array<POSGroups>();

    this.hasSelectedGast = false;
    this.hasSelectedArtikel = false;
    this.hasSelectedPOSConfig = false;
    this.hasMoreArtikelGruppen = false;
    this.hasSelectedBestellung = false;
    this.newstyle = true;

    this.cfgGKDisabled = true;
    this.cfgKassaDisabled = true;
    this.cfgBestellungDisabled = true;
    this.cfgBestellungRrepeatable = true;

    this.btnBestellungDisabled = true;
    this.btnGKDisabled = true;
    this.btnKassaDisabled = true;
    this.btnPrintDisabled = false;
    this.modeBestellungen = false;

    this.lastkeydown1 = 0;
    this.searchString = "";
    
    this.setSelectableSettings();
    this.showAllBestellungen = false;
    this.requestDate = new Date();

    this.btnActionDisabledSendBestellungToGK = false;
    this.btnActionDisabledSendBestellungToKassa = false;

    this.btnDisabledSendBestellungToGK = false;
    this.btnDisabledSendBestellungToKassa = false;

    this.heute = true;
    this.morgen = false;
    this.alle = false;


    this.setSelectableSettings();
    this.loadPOSConfig();
  }

  setPOSSettings() {
    if (this.myPosConfig) {
      if (!this.myPosConfig.intervall_size)
        return;

      if (this.myPosConfig.intervall_size.toString() === "0") // 15min default
        return;

      if (this.myPosConfig.intervall_size.toString() === "1") // 30min default
        this.steps = { hour: 1, minute: 30 };

      if (this.myPosConfig.intervall_size.toString() === "2") // 60min default
        this.steps = { hour: 1, minute: 60 };

    }
  }

 
  changeGroup(obj: POSGroups) {
    this.currentPOSGroups = obj;
  }

  getGroupButtonClass(obj: POSGroups)
  {
    //console.log(obj);

    if (this.currentPOSGroups.groupName === obj.groupName)
    {
      return "btn btn-lg btn-primary btn-block";
    } else
    {
      return "btn btn-lg btn-default btn-block";
    }
  }


  SetSelectPOS(posname: string) {

    this.myPosConfig = this.PosConfigList.find(e => e.posName.toLowerCase() === posname.toLowerCase());

    if (this.myPosConfig) {
      if (!this.myPosConfig.artikel)
        this.myPosConfig.artikel = new Array<Artikel>();

      //console.log("pos with Id " + this.myPosConfig.posGuid + " has been selected.");

      if (this.myPosConfig.artikel)
        this.myPosConfig.artikel.sort((a, b) => (a.orderId > b.orderId) ? 1 : ((b.orderId > a.orderId) ? -1 : 0));

      this.hasSelectedPOSConfig = true;
      this.posname = this.myPosConfig.posName;


      this.cfgGKDisabled = !this.myPosConfig.sendToGK;
      this.cfgKassaDisabled = !this.myPosConfig.sendToKassa;
      this.cfgBestellungDisabled = !this.myPosConfig.isBestellungPOS;

      if (this.cfgBestellungDisabled)
        this.showBestellungCol = "hidden";

      this.fillArtikelGruppen();

      this.loadGastList();

      this.setButtonState();

    } else
    {
      //console.log("no valid POS Found");
    }
  }

  showBtnHasBest(dataItem)
  {
    if (dataItem.hasBestellung === true)
      return "";
    else
      return "hidden";
  }

  showBtnHasNoBEst(dataItem) {
    if (dataItem.hasBestellung === true)
      return "hidden";
    else
      return "";

  }

  showBtnIsRepeatable(dataItem) {
    if (dataItem.repeatable === true)
      return "";
    else
      return "hidden";
  }

  showBtnIsNotRepeatable(dataItem) {
    if (dataItem.repeatable === true)
      return "hidden";
    else
      return "";
  }


  showBtnIsGuestcreated(dataItem) {
    
    if (dataItem.guestcreated === true)
      return "";
    else
      return "hidden";
  }

  showBtnIsNotGuestcreated(dataItem) {
    
    if (dataItem.guestcreated === true)
      return "hidden";
    else
      return "";
  }


  showBtnGK()
  {
    
    if (this.cfgGKDisabled === true)
      return "hidden";
    else
      return "";
  }
  showBtnKA()
  {
 
    if (this.cfgKassaDisabled === true)
      return "hidden";
    else
      return "";
  }


  //********* --> Basket Item Commands Remove Line / Decrease Count / InCrease Count / Add New Artikel

  public removeAllArtikel(dataItem) {
    if (this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBasket.artikel.indexOf(updateItem);

      this.myBasket.artikel.splice(index, 1);

      if (this.myBasket.artikel.length < 1)
        this.hasSelectedArtikel = false;

    }

    this.updateSumme();
    this.setButtonState();
  }

  public decreaseArtikelCount(dataItem) {


    if (this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBasket.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl - 1;

      if (updateItem.anzahl > 0)
        this.myBasket.artikel[index] = updateItem;
      else {
        this.myBasket.artikel.splice(index, 1);

        if (this.myBasket.artikel.length < 1)
          this.hasSelectedArtikel = false;
      }
    }

    this.updateSumme();
    this.setButtonState();

  }

  public increaseArtikelCount(dataItem) {

   

    if (this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBasket.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl + 1;

      this.myBasket.artikel[index] = updateItem;
    }

    this.updateSumme();
    this.setButtonState();

  }

  onAddArtikel(a: Artikel) {
    this.hasSelectedArtikel = true;

    if (this.myBasket.artikel.find(x => x.artikelnummer === a.artikelnummer)) {
      const updateItem = this.myBasket.artikel.find(x => x.artikelnummer === a.artikelnummer);
      const index = this.myBasket.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl + 1;
      updateItem.gesamt = updateItem.anzahl * Number(updateItem.bruttopreis);

      this.myBasket.artikel[index] = updateItem;

    } else {
      a.anzahl = 1;
      this.myBasket.artikel.push(a);
    }

    ////console.log("Add Artikel to Basket" + a.artikelnummer);

    this.updateSumme();
    this.setButtonState();
  }

  selectedKeysChange(selectedItem: any) {
    this.onAddArtikel(this.myPosConfig.artikel.find(i => i.artikelnummer === selectedItem[0]));
  }

  //********* <-- Basket Item Commands Remove Line / Decrease Count / InCrease Count / Add New Artikel


  //********* --> Gast Commands ***********************************************************

  SearchGast() {

    const suche = this.searchString.toLowerCase();
    const foundgaeste: GastDisplay[] = new Array<GastDisplay>();

    if (this.searchString.length === 0)
      this.filtergaeste = this.gaeste;

    if (this.searchString.length > 0) {
      this.gaeste.forEach(function (value) {
        ////console.log(value);

        if (value.vorname) {
          if (value.vorname.toLowerCase().indexOf(suche) >= 0) {

            foundgaeste.push(value);
            return;
          }
        }
        if (value.nachname) {
          if (value.nachname.toLowerCase().indexOf(suche) >= 0) {
            foundgaeste.push(value);
            return;
          }
        }
        if (value.mietobjektBezeichnung) {
          if (value.mietobjektBezeichnung.toLowerCase().indexOf(suche) >= 0) {
            foundgaeste.push(value);
            return;
          }
        }
      }
      );
    }

    if (foundgaeste.length > 0)
      this.filtergaeste = foundgaeste;
  }

  selectedKeysChangeGast(selectedItem: any) {
    //console.log(selectedItem);
    this.onSelectGast(this.gaeste.find(i => i.gkid === selectedItem[0]));
  }

  onSelectGast(gast: GastDisplay) {

    this.selectedGast = gast;
    this.hasSelectedGast = true;
    this.setButtonState();
  }
  onCancelSelectedGast() {
    this.selectedGast = null;
    this.hasSelectedGast = false;
    this.setButtonState();
  }

  //********* <-- Gast Commands ***********************************************************

  //********* --> Basket / Order Commands *************************************************

  onCancelSelectedBasket() {
    this.myBasket.artikel = new Array<Artikel>();
    this.hasSelectedArtikel = false;
    this.updateSumme();
    this.setButtonState();
  }

  onSendBasketToGK() {
    this.btnGKDisabled = true;
    this.myBasket.kassa = false;
    this.myBasket.gaestekonto = true;
    this.myBasket.gkid = this.selectedGast.gkid;

    this.sendBasket();
  }

  onSendBasketToKassa() {
    this.btnKassaDisabled = true;
    this.myBasket.kassa = true;
    this.myBasket.gaestekonto = false;
    this.myBasket.gkid = 0;

    this.sendBasket();

  }

  onSaveOrder() {
    //Convert myBasket to Bestellung and Save
    const bestellung: Bestellung = {} as Bestellung;
    bestellung.artikel = new Array<Artikel>();
    bestellung.artikel = this.myBasket.artikel;
    bestellung.posGuid = this.myPosConfig.posGuid;
    bestellung.gkid = this.selectedGast.gkid;
    bestellung.vorname = this.selectedGast.vorname;
    bestellung.nachname = this.selectedGast.nachname;
    bestellung.mietobjektBezeichnung = this.selectedGast.mietobjektBezeichnung;

    this.posService.saveBestellungen(bestellung)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this._Init();
        } else {
          this.notification.error(res.errorMessage);
          this._Init();
        }

      });
  }

  //********* <-- Basket / Order Commands *************************************************

  //********* --> Bestellung Commands *****************************************************

  
  public onChangeRequestDate(): void {
    this.heute = false;
    this.morgen = false;
    this.alle = false;
    this.loadBestellungList();
    this.loadBackliste();
  }


  //public onChangeRequestBestellungDate(): void
  //{
  //  this.loadBestellungList();
  //}

  //public onChangeBacklisteRequestDate(): void {
  //  this.loadBackliste();
  //}

  public onAddArtikelBestellung(a: Artikel) {

    if (this.selectedBestellung.artikel.find(x => x.artikelnummer === a.artikelnummer)) {
      const updateItem = this.selectedBestellung.artikel.find(x => x.artikelnummer === a.artikelnummer);
      const index = this.selectedBestellung.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl + 1;

      this.selectedBestellung.artikel[index] = updateItem;

    } else {
      a.anzahl = 1;
      this.selectedBestellung.artikel.push(a);
    }

    //console.log("Add Artikel to SelectedBestellung" + a.artikelnummer);


  }





  selectedKeysChangeBestellung(selectedItem: any) {
    if (!this.btnActionDisabledSendBestellungToGK)
    {
      if (!this.btnActionDisabledSendBestellungToKassa)
      {
        const _bestellung = this.bestellungen.find(i => i.bestellGuid === selectedItem[0]);
        this.onSelectBestellung(_bestellung);
      }
    }

  }

  onSelectBestellung(bestellung: Bestellung) {

    console.log("CALL --> onSelectBestellung");

    this.selectedBestellung = bestellung;

    this._abholungAm = new Date(bestellung.abholungAm);

    this._min = new Date(this._abholungAm.getFullYear(), this._abholungAm.getMonth(), this._abholungAm.getDay(), 8, 0, 0);
    this._max = new Date(this._abholungAm.getFullYear(), this._abholungAm.getMonth(), this._abholungAm.getDay(), 11, 0, 0);

    this.hasSelectedBestellung = true;

  }

  _btnActionDisabledSendBestellungToGK(value: boolean) {
    this.btnActionDisabledSendBestellungToGK = value;
  }

  _btnActionDisabledSendBestellungToKassa(value: boolean) {
    this.btnActionDisabledSendBestellungToKassa = value;
  }

  _btnDisabledSendBestellungToGK(value: boolean) {
    this.btnDisabledSendBestellungToGK = value;
  }

  _btnDisabledSendBestellungToKassa(value: boolean) {
    this.btnDisabledSendBestellungToKassa = value;
  }



  actionSendBestellungToGK(item: any) {
    console.log("GK push");
    this._btnActionDisabledSendBestellungToGK(true);
    this.selectedBestellung = item;
    this.sendBestellungToGK();
  }

  actionSendBestellungToKassa(item: any) {
    console.log("KA push");
    this._btnActionDisabledSendBestellungToKassa(true);
    this.selectedBestellung = item;
    this.sendBestellungToKassa();
  }


  sendBestellungToKassa()
  {
    this._btnDisabledSendBestellungToKassa(true);

    this.myBasket = {} as Basket;
    this.myBasket.artikel = this.selectedBestellung.artikel;
    this.myBasket.gkid = this.selectedBestellung.gkid;

    this.myBasket.kassa = true;
    this.myBasket.gaestekonto = false;

    this.posService.sendBasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {

          this.notification.success(res.successMessage);

          if (!this.selectedBestellung.repeatable)
            this.deleteBestellung();
          else {
            this.extendOrder();
          }
          this.resetBestellungSelection();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }
   

  sendBestellungToGK() {

    this._btnDisabledSendBestellungToGK(true);

    this.myBasket = {} as Basket;
    this.myBasket.artikel = this.selectedBestellung.artikel;
    this.myBasket.gkid = this.selectedBestellung.gkid;

    this.myBasket.kassa = false;
    this.myBasket.gaestekonto = true;

    this.posService.sendBasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {

          this.notification.success(res.successMessage);

          if (!this.selectedBestellung.repeatable)
            this.deleteBestellung();
          else {
            this.extendOrder();
          }
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }
 
 


  public decreaseArtikelBestellungCount(dataItem) {
    //console.log('decreaseArtikelBestellungCount ' + dataItem);

    if (this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.selectedBestellung.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl - 1;

      if (updateItem.anzahl > 0)
        this.selectedBestellung.artikel[index] = updateItem;
      else {
        this.selectedBestellung.artikel.splice(index, 1);
      }
    }
  }

  public increaseArtikelBestellungCount(dataItem) {
    //console.log('increaseArtikelBestellungCount ' + dataItem);

    if (this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.selectedBestellung.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl + 1;

      this.selectedBestellung.artikel[index] = updateItem;
    }

  }

  public removeArtikelBestellung(dataItem) {
    //console.log('removeArtikelBestellung ' + dataItem);

    if (this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.selectedBestellung.artikel.indexOf(updateItem);
      this.selectedBestellung.artikel.splice(index, 1);
    }
  }






  //********* <-- Bestellung Commands *****************************************************

  //************ --> Service Calls *********************************

  loadGastList() {

    const request = {} as RequestGastListeViewModel;
    request.posGuid = this.myPosConfig.posGuid;
    request.posName = this.myPosConfig.posName;

    this.posService.getDisplayGast(request)
      .subscribe((res: any) => {
        this.gaeste = res;
        this.filtergaeste = res;
      });
  }

  loadPOSConfig() {
    this.posService.getAllPos()
      .subscribe((res: PosConfig[]) => {
        this.PosConfigList = res;
        //console.log(res);
        if (this.posService.login) {
          this.SetSelectPOS(this.posService.login.pos);
        }

      });
  }

  loadArtikel() {


    this.posService.getProducts()
      .subscribe((res: Artikel[]) => {
        this.artikel = res;
        //console.log(res);
      });
  }

  loadBestellungList() {

    const request = {} as RequestGetBestellungViewModel;
    request.posGuid = this.myPosConfig.posGuid;
    request.showAll = this.showAllBestellungen;
    request.RequestDate = this.requestDate;

    this.posService.getBestellungen(request)
      .subscribe((res: any) => {
        this.bestellungen = res;
        console.log("reload Bestellungen");
      });
  }

  loadBackliste() {

    const request = {} as RequestBestellListenViewModel;
    request.posGuid = this.myPosConfig.posGuid;
    request.RequestDate = this.requestDate;

    this.posService.getTagesBestellListe(request)
      .subscribe((res: any) => {
        this.Backliste = res;
        //console.log(res);
      });

    this.posService.getTagesBestellDetailListe(request)
      .subscribe((res: any) => {
        this.bestelldetailprintlist = res;
        //console.log(res);
      });
  }



  saveBestellung() {

    this.selectedBestellung.abholungAm = this._abholungAm;

    this.posService.saveBestellungen(this.selectedBestellung)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.reset();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }


  sendBasket()
  {
    this.posService.sendBasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);

          this.reset();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }

  printTagesBestellungEtiketten() {

    const request = {} as RequestBestellListenViewModel;
    request.posGuid = this.myPosConfig.posGuid;
    request.RequestDate = this.requestDate;

    this.posService.printTagesBestellungEtiketten(request)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.resetBestellungSelection();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }

 
  extendOrder() {
    this.posService.extendOrder(this.selectedBestellung)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.resetBestellungSelection();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }


  deleteBestellung() {
    this.posService.deleteBestellung(this.selectedBestellung)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.resetBestellungSelection();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }

  //************ <-- Service Calls *********************************


  //************ Helper ****************************************

  updateSumme() {
    this.myBasket.summe = 0;

    for (const entry of this.myBasket.artikel) {
      const _brutto = Number(entry.bruttopreis);
      ////console.log(_brutto);
      this.myBasket.summe = this.myBasket.summe + (entry.anzahl * _brutto);
    }
  }

  setButtonState() {
    if (!this.cfgGKDisabled) {
      //GK on POSConfig enabled
      if (this.hasSelectedArtikel && this.hasSelectedGast)
        this.btnGKDisabled = false;
      else
        this.btnGKDisabled = true;
    }
    if (!this.cfgKassaDisabled) {
      //Kassa on POSConfig enabled
      if (this.hasSelectedArtikel && !this.hasSelectedGast)
        this.btnKassaDisabled = false;
      else
        this.btnKassaDisabled = true;
    }

    if (!this.cfgBestellungDisabled) {
      //Bestellung on POSConfig enabled
      if (this.hasSelectedArtikel && this.hasSelectedGast) {
        this.btnBestellungDisabled = false;
      } else {
        this.btnBestellungDisabled = true;
      }
    }
  }

  fillArtikelGruppen() {

    for (const artikel of this.myPosConfig.artikel) {
      if (this.artikelGruppen.find(x => x.groupName === artikel.groupName)) {
        const updateItem = this.artikelGruppen.find(x => x.groupName === artikel.groupName);
        const index = this.artikelGruppen.indexOf(updateItem);

        updateItem.artikel.push(artikel);
        this.artikelGruppen[index] = updateItem;

      } else {
        const newGroup: POSGroups = {} as POSGroups;
        newGroup.groupName = artikel.groupName;
        newGroup.artikel = new Array<Artikel>();
        newGroup.artikel.push(artikel);
        this.artikelGruppen.push(newGroup);
      }
    }

    if (this.artikelGruppen.length > 1)
    {
      this.hasMoreArtikelGruppen = true;
      this.currentPOSGroups = this.artikelGruppen[0];
    }
    else
    {
      this.hasMoreArtikelGruppen = false;
      this.currentartikel = this.myPosConfig.artikel;
    }

  }

  navhome() {
    this.router.navigate(["login"]);
  }

  logout() {
    this.posService.Logout();
    this.router.navigate(["login"]);
  }

  setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  colorCode(code: string): SafeStyle {
    let result;

    switch (code) {
      case 'C1':
        result = '#FFBA80';
        break;
      case 'C2':
        result = '#B2F699';
        break;
      default:
        result = 'transparent';
        break;
    }

    return this.sanitizer.bypassSecurityTrustStyle(result);
  }

  handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.notification.error(`client-side Error Code: ${error.error.message}`);

    } else {
      // server-side error
      this.notification.error(`server-side Error Code: ${error.status}\nMessage: ${error.message}`);

    }
    this.errorReceived = true;
    return Observable.throw(error);
  }

  public onTabSelect(e) {
    //console.log(e);
  }

  getAbholungClass(abholung: Date) {

    //console.log(abholung);

    const today: Date = new Date();
    const check: Date = new Date(abholung);
    check.setDate(check.getDate() + 1);

    if (check < today)
      return "bg-warning";
  }


  changeMode() {
    
    this.reset();
    this.resetBestellungSelection();
    this.setButtonState();

    this.modeBestellungen = !this.modeBestellungen;

    if (this.modeBestellungen) {
      this.loadBestellungList();
      this.loadBackliste();
    }
  }

  bestellListeHeute()
  {
    this.requestDate = new Date();

    this.heute = true;
    this.morgen = false;
    this.alle = false;

    this.showAllBestellungen = false;
    this.loadBestellungList();
    this.loadBackliste();
    
  }

  bestellListeMorgen()
  {
    const today = new Date();
    this.requestDate = new Date(today.setDate(today.getDate() + 1));

    this.heute = false;
    this.morgen = true;
    this.alle = false;
    

    this.showAllBestellungen = false;
    this.loadBestellungList();
    this.loadBackliste();
    
  }



  bestellListeAlle() {

    this.heute = false;
    this.morgen = false;
    this.alle = true;



   this.showAllBestellungen = true;
   this.loadBestellungList();
    this.loadBackliste();
    
  }

  resetBestellungSelection() {
    this.hasSelectedBestellung = false;
    this.selectedBestellung = null;
    this.loadBestellungList();
    this.loadBackliste();
    this.loadGastList();
    this._btnActionDisabledSendBestellungToGK(false);
    this._btnActionDisabledSendBestellungToKassa(false);
    this._btnDisabledSendBestellungToGK(false);
    this._btnDisabledSendBestellungToKassa(false);
  }


  reset() {

    //this.btnKassaDisabled = false;
    //this.btnGKDisabled = false;
    this.hasSelectedGast = false;
    this.hasSelectedArtikel = false;
    this.myBasket = {} as Basket;
    this.myBasket.artikel = new Array<Artikel>();

    this.setButtonState();
  }

  //************ Helper ****************************************
}



