import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PosService } from 'src/services/pos.services';
import { NotificationService } from 'src/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/config/app.config';
import { interval, Subscription } from 'rxjs';
import { ServerStreamResponseOptions } from 'http2';
import {
  AxisLabelClickEvent,
  LegendItemClickEvent,
  LegendItemHoverEvent,
  PlotAreaClickEvent,
  PlotAreaHoverEvent,
  SeriesClickEvent,
  SeriesHoverEvent,
  SelectEndEvent,
} from "@progress/kendo-angular-charts";

declare function pos_pay_test(): any;

@Component({
  selector: 'app-home',
  templateUrl: './energy.component.html',
})
export class EnergyComponent implements OnInit {
  public myGaestekonto: GaestekontoViewModel;
  //public myPament: PaymentViewModel;
  private loginReq: LoginRequestModel;
  private LoginCode: string;
  private gkid: string;
  private resourcePath: string;
  private ErrorMessage: string;
  private SuccessMessage: string;
  private _loadedGK: boolean;
  private _loadedStrom: boolean;
  private _StromAktiv: boolean;
  private _mySmartMeter: SmartMeter;
  private _stringSmartEnergyHeader: string;
  private _requestSmartMeterValueDetails: RequestSmartMeterValueDetails;
  private _SmartMeterDetailConsumption: DetailConsumption;
  public subscription: Subscription;
  public detaildescription: string[];
  public detailseriesData: number[];

  public _total_Consumption_kWh: number;
  public _daylie_Consumption_kWh: number;
  public _forecast_Consumption_kWh: number;
  public _forcast_Price: number;
  public _isKWHInkl: boolean;

  public transitions = false;
  public navigatorStep: number;
  public step: number;
  public min = 0;
  public max = 20;

  private _startValue: string;
  private _currentValue: string;


  private Header: string;
  private Description: string;
  private Date: string;
  private Pitch: string;
  private Arrival: string;
  private Departure: string;
  private Days: string;
  private Subtotal: string;
  private Deposit: string;
  private Invoiceamount: string;
  private PaymentDescription: string;
  private ButtonPay: string;
  private OpenInvocie: string;
  public opened = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private posService: PosService, private notification: NotificationService, private _config: AppConfig, public translate: TranslateService) {

    console.log("EnergyComponent init ...");
    this._stringSmartEnergyHeader = "Energie Verbrauch";
    const source = interval(5000);
    this.subscription = source.subscribe(val => this.refreshEnergyData());

  }

  ngOnInit() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.activatedRoute.queryParams.subscribe(params => {
      console.log("EnergyComponent ngOnInit ...");
      this.resourcePath = params['resourcePath'];
      this.LoginCode = params['idx'];
      this.gkid = params['gkid'];
      this._loadedGK = false;
      this._loadedStrom = false;
      this._StromAktiv = false;
      this.loadGK();

    });
  }

  ngOnDestroy()
  {
    console.log('unsubscribe');
    this.subscription.unsubscribe();
  }

  _getSuccessMessage(): string { return this.SuccessMessage; }
  _getErrorMessage(): string { return this.ErrorMessage; }


  _changeLan(lang: string) {
    this.translate.use(lang);
    this._config.useLang(lang);
  }

  _login() {

    if (this.posService.validLogin === true) {
      this.notification.success("Login gefunden.");
    } else {

      this.loginReq.UserID = this.LoginCode;

      this.posService.Login(this.loginReq)
        .subscribe((res: any) => {

          if (res.success) {

            this.posService.login = res;
            this.posService.validLogin = true;
            this.loadGK();
          } else {
            this.posService.validLogin = false;
            this.notification.error(res.errorMessage);
          }
        });
    }
  }




  loadGK() {
    if (this.posService.login) {
      const request = {} as RequestGaestekontoViewModel;
      request.gkid = this.posService.login.gkid;

      this.posService.getGeastekonto(request)
        .subscribe((res: any) => {
          this.myGaestekonto = res;
          console.log(res);
          this._loadedGK = true;
          this._startValue = this.myGaestekonto.startMeterValue;
          this._StromAktiv = this.myGaestekonto.stromAktiv;
        });
    }
  }


  navhome() {
    this.router.navigate(["welcome"]);
  }

  logout() {
    this.posService.Logout();
    this.router.navigate(["login"]);
  }

  refreshEnergyData()
  {
    if (this._StromAktiv)
    {
      this.posService.getSmartEnergy(this.myGaestekonto.smartEChannel)
        .subscribe((res: any) => {
          this._loadedStrom = true;
          this._mySmartMeter = res;
          this._currentValue = this._mySmartMeter.Total_activeenergy.toString();

          //...........
          this._requestSmartMeterValueDetails = {} as RequestSmartMeterValueDetails;
          this._requestSmartMeterValueDetails.description = this._mySmartMeter.Description;
          this._requestSmartMeterValueDetails.aufenthaltVon = this.myGaestekonto.aufenthaltVon;
          this._requestSmartMeterValueDetails.aufenthaltBis = this.myGaestekonto.aufenthaltBis;
          this._requestSmartMeterValueDetails.startValue = this._startValue;
          this._requestSmartMeterValueDetails.currentValue = this._currentValue;

          this.posService.getSmartEnergyDetail(this._requestSmartMeterValueDetails)
            .subscribe((res: any) => {

              this.detailseriesData = res.detail_seriesData;
              this.detaildescription = res.detail_description;

              this._total_Consumption_kWh = res.total_Consumption_kWh;
              this._daylie_Consumption_kWh = res.daylie_Consumption_kWh;
              this._forecast_Consumption_kWh = res.forecast_Consumption_kWh;

              this._forcast_Price = res.forcast_Price;
              this._isKWHInkl = res.isKWHInkl;


              if (this.detaildescription)
                this.navigatorStep = Math.floor(this.detaildescription.length / 10);



            }, error => {
              console.error(error);
            })

          //...........

        }, error => {
          console.error(error);
          this.subscription.unsubscribe();
          this._StromAktiv = false;
          this._stringSmartEnergyHeader = "Ihre EnergieDaten können aktuell nicht geladen werden...";
        })

      

    }
  }


  public onSelectEnd(args: SelectEndEvent): void {
    // set the axis range displayed in the main pane to the selected range
    this.min = args.from;
    this.max = args.to;

    // stop the animations
    this.transitions = false;

    // set the main axis ticks and labels step to prevent the axis from becoming too cluttered
    this.step = Math.floor((this.max - this.min) / 10);
  }



}
