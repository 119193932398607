import { Injectable } from '@angular/core';
/*import { Http, Response, RequestOptionsArgs, RequestMethod, Headers } from '@angular/http';*/
import { IConfiguration } from '../models/configuration.model';
import { StorageService } from './storage.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import { Observer } from 'rxjs/Observer';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class ConfigurationService {
  public serverSettings: IConfiguration;
  public APIServer: string;
  // observable that is fired when settings are loaded from server
  private settingsLoadedSource = new Subject();
  settingsLoaded$ = this.settingsLoadedSource.asObservable();
  isReady: boolean = false;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  load() {
   /* this.serverSettings = {} as IConfiguration;*/
    const baseURI = document.baseURI!.endsWith('/') ? document.baseURI : `${document.baseURI}/`;
    let url = `${baseURI}assets/json-config/posSettings.json`;

    this.http.get(url).subscribe((response: any) => {
      this.APIServer = response.APIServer;
      this.serverSettings.APIServer = response.APIServer;
      this.serverSettings.WebServer = response.WebServer;
      this.serverSettings.oppurl = response.oppurl;

      this.storageService.store('APIServer', this.serverSettings.APIServer);
      this.storageService.store('WebServer', this.serverSettings.WebServer);
      this.storageService.store('oppurl', this.serverSettings.oppurl);
      

      this.isReady = true;
      console.log('Settings loaded complete...  ');
      this.settingsLoadedSource.next();
    });



    //return this.http.get(url).map((response: any) => {
    //  if (response.status === 204) {
    //    console.log('204');
    //    return null;
    //  }
    //  console.log('123');
    //  this.APIServer = response.APIServer;
    //  this.serverSettings.APIServer = response.APIServer;
    //  this.serverSettings.WebServer = response.WebServer;
    //  this.serverSettings.oppurl = response.oppurl;
    //  this.isReady = true;
    //  console.log('123');
    //  return response.json();
    //});



    //return this.http.get(url).map((response:any) => {
    //  this.APIServer = response.APIServer;
    //  this.serverSettings.APIServer = response.APIServer;
    //  this.serverSettings.WebServer = response.WebServer;
    //  this.serverSettings.oppurl = response.oppurl;
    //  this.isReady = true;
    //  console.log('Settings loaded complete...  ');
    //})


    //this.http.get(url).map((response: any) => {
    //  this.APIServer = response.APIServer;
    //  this.serverSettings.APIServer = response.APIServer;
    //  this.serverSettings.WebServer = response.WebServer;
    //  this.serverSettings.oppurl = response.oppurl;
    //  this.isReady = true;
    //  console.log('Settings loaded complete...  ');
    //  return true;
    //});




    //this.http.get<IConfiguration>(url).toPromise().then(function (settings) {
    //  console.log('123');
    //  console.log(settings);
    //  console.log('678');
    //});

    
  }
}
