import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/services/notification.service';
import { config, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  successSub: Subscription;
  warningSub: Subscription;
  errorSub: Subscription;

  constructor(public toastr: ToastrService, private notification: NotificationService,private configurationService: ConfigurationService) {

  }

  ngOnInit() {

    //console.log('app on init');

    this.successSub = this.notification.successMessage$.subscribe(res => {
      this.showSuccess(res);
    });

    this.warningSub = this.notification.warningMessage$.subscribe(res => {
      this.showWarning(res);
    });

    this.errorSub = this.notification.errorMessage$.subscribe(res => {
      this.showError(res);
    });

    //Get configuration from server environment variables:
    //this.configurationService.load();

  }
  showSuccess(message: any) {
    //console.warn('Toast S schould show ' + message);
    //success(message ?: string, title ?: string, override ?: Partial<IndividualConfig>): ActiveToast<any>;
    this.toastr.success(message, 'Hinweis');
  }

  showWarning(message: any) {
    //console.warn('Toast W schould show ' + message);
    //this.toastr.warning(message, '', { toastLife: '6500', enableHTML: true });
    this.toastr.warning(message, 'Warnung');
  }

  showError(message: any) {
    //console.warn('Toast E schould show ' + message);
    //this.toastr.error(message, '', { dismiss: 'click', enableHTML: true });
    this.toastr.error(message, 'Fehler');
  }


}
