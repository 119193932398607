import { Component, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router, RouteReuseStrategy } from "@angular/router";
import { NotificationService } from 'src/services/notification.service';
import { PosService } from 'src/services/pos.services';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { element } from 'protractor';
//import JSMpeg from 'jsmpeg-player';

@Component({
  selector: 'app-home',
  templateUrl: './pos_internal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./pdf-styles.css', './page-template.css']
})


export class PosInternalComponent {

  public hasSelectedPOSConfig: boolean;
  public posname: string;
  public PosConfigList: PosConfig[];
  public myPosConfig: PosConfig;
  public selectableSettings: SelectableSettings;
  public errorReceived: boolean;
  public modeBestellungen: boolean;

  public artikel: Artikel[];
  public gaeste: GastDisplay[];
  public bestellungen: Bestellung[];
  public bulk_bestellungen: Bestellung[];
  public bestelldetailprintlist: BestellungDetailViewModel[];
  public filtergaeste: GastDisplay[];
  public searchString: string;
  public showAllBestellungen: boolean;
  public showGastListe: boolean;
  public hasSelectedGast: boolean;
  public hasSelectedArtikel: boolean;

  public hasSelectedBestellung: boolean;

  public selectedGast: GastDisplay;
  public selectedBestellung: Bestellung;
  public myBasket: Basket;


  public lastkeydown1: number;
  public userData: any[];
  public newstyle: boolean;
  //public requestBacklisteDate: Date;
  public Backliste: Bestellung;

  public requestDate: Date;
  public heute: boolean;
  public morgen: boolean;
  public alle: boolean;
  public locked: boolean;

  public showBestellungCol: string;

  public cfgGKDisabled: boolean;
  public cfgKassaDisabled: boolean;
  public cfgBestellungDisabled: boolean;
  public cfgBestellungRrepeatable: boolean;

  public hasMoreArtikelGruppen: boolean;
  public btnGKDisabled: boolean;
  public btnKassaDisabled: boolean;
  public btnBestellungDisabled: boolean;
  public btnPrintDisabled: boolean;

  public artikelGruppen: POSGroups[];

  public steps: any = { hour: 1, minute: 15 };

  public _abholungAm: Date;
  public _abholungAmNewOrder: Date;
  public _newOrder_repeatable: boolean;

  public _min: Date;
  public _max: Date;
  //.............
  public btnActionDisabledSendAllCurrentBestellungToGK: boolean;
  public btnActionDisabledSendBestellungToGK: boolean;
  public btnActionDisabledSendBestellungToKassa: boolean;


  public btnDisabledSendBestellungToGK: boolean;
  public btnDisabledSendBestellungToKassa: boolean;

  //Touch Kassa
  public currentartikel: Artikel[];
  public currentPOSGroups: POSGroups;

  //parking
  public _hasparkingidx1: boolean;
  public _hasparkingidx2: boolean;
  public _hasparkingidx3: boolean;
  public _hasparkingidx4: boolean;
  public _hasparkingidx5: boolean;
  public _hasparkingidx6: boolean;

  // waiting
  public opened = false;

  constructor(private router: Router, private notification: NotificationService, private posService: PosService, private sanitizer: DomSanitizer) {
    this._Init();

    if (!this.posService.login) {
      this.router.navigate(["login"]);
    }
  }

  _Init() {

    //...Kassa....
    this.currentartikel = new Array<Artikel>();
    this.currentPOSGroups = {} as POSGroups;
    //............

    this.errorReceived = false;

    this.showBestellungCol = "";
    this.PosConfigList = new Array<PosConfig>();
    this.bestellungen = new Array<Bestellung>();
    this.myBasket = {} as Basket;
    this.myPosConfig = {} as PosConfig;
    this.Backliste = {} as Bestellung;
    this.myBasket.artikel = new Array<Artikel>();
    this.artikelGruppen = new Array<POSGroups>();

    this._hasparkingidx1 = false;
    this._hasparkingidx2 = false;
    this._hasparkingidx3 = false;
    this._hasparkingidx4 = false;
    this._hasparkingidx5 = false;
    this._hasparkingidx6 = false;

    this.hasSelectedGast = false;
    this.hasSelectedArtikel = false;
    this.hasSelectedPOSConfig = false;
    this.hasMoreArtikelGruppen = false;
    this.hasSelectedBestellung = false;
    this._newOrder_repeatable = false;
    this.newstyle = true;
    this.showGastListe = false;
    this.cfgGKDisabled = true;
    this.cfgKassaDisabled = true;
    this.cfgBestellungDisabled = true;
    this.cfgBestellungRrepeatable = true;

    this.btnBestellungDisabled = true;
    this.btnGKDisabled = true;
    this.btnKassaDisabled = true;
    this.btnPrintDisabled = false;
    this.modeBestellungen = false;

    this.lastkeydown1 = 0;
    this.searchString = "";

    this.setSelectableSettings();
    this.showAllBestellungen = false;
    this.requestDate = new Date();

    this.btnActionDisabledSendBestellungToGK = false;
    this.btnActionDisabledSendBestellungToKassa = false;

    this.btnDisabledSendBestellungToGK = false;
    this.btnDisabledSendBestellungToKassa = false;

    this.heute = true;
    this.morgen = false;
    this.alle = false;
    this.locked = false;


    this.setSelectableSettings();
    this.loadPOSConfig();
    //this.initCam();
  }

  setPOSSettings() {
    if (this.myPosConfig)
    {
      this._hasParkinBeleg();

      if (!this.myPosConfig.intervall_size)
        return;

      if (this.myPosConfig.intervall_size.toString() === "0") // 15min default
        return;

      if (this.myPosConfig.intervall_size.toString() === "1") // 30min default
        this.steps = { hour: 1, minute: 30 };

      if (this.myPosConfig.intervall_size.toString() === "2") // 60min default
        this.steps = { hour: 1, minute: 60 };
    }
  }

  initCam() {

    //this.drawOnCanvas();

    //let player = new JSMpeg.Player('rtsp://admin:KTechcam$4@192.168.88.40:554/h264Preview_01_main', {
    //  canvas: this.streamingcanvas.nativeElement, autoplay: true, audio: false, loop: true
    //})

  }


  //drawOnCanvas() {
  //  this.ctx = this.mycanvas.nativeElement.getContext('2d');

  //  navigator.mediaDevices
  //    .getUserMedia({
  //      audio: false,
  //      video: true
  //    })
  //    .then(stream => {
  //      this.myvideo.nativeElement.srcObject = "rtsp://admin:KTechcam$4@192.168.88.40:554/h264Preview_01_main";//stream;
  //      this.drawCanvas();
  //      //this.myvideo.nativeElement.play();
  //    });
  //}

  //drawCanvas() {
  //  this.mycanvas.nativeElement.width = this.myvideo.nativeElement.clientWidth;
  //  this.mycanvas.nativeElement.height = this.myvideo.nativeElement.clientHeight;
  //  this.ctx.drawImage(
  //    this.myvideo.nativeElement.srcObject,
  //    0,
  //    0,
  //    this.mycanvas.nativeElement.width,
  //    this.mycanvas.nativeElement.height
  //  );
  //}


 

  _hasParkinBeleg()
  {

    let _basket: Basket;
    _basket = {} as Basket;
    _basket.posGuid = this.myPosConfig.posGuid;
    _basket.parkingid = 1;

    this.posService.hasparkingbasket(_basket)
      .subscribe((res: any) => {
        this._hasparkingidx1 = res.hasparkingbasket1;
        this._hasparkingidx2 = res.hasparkingbasket2;
        this._hasparkingidx3 = res.hasparkingbasket3;
        this._hasparkingidx4 = res.hasparkingbasket4;
        this._hasparkingidx5 = res.hasparkingbasket5;
        this._hasparkingidx6 = res.hasparkingbasket6;
      });
  }

  //************ Beleg Parking *********************************
  getparking(idx: any) {

    if (this.myBasket.artikel.length > 0)
    {
      this.notification.warning("Es befinden sich Artikel im Warenkorb - Parkbeleg laden nicht möglich !");
    }
    else
    {

      this.myBasket.parkingid = idx;
      this.myBasket.posGuid = this.myPosConfig.posGuid;
      this.loadParkingBasket();
    }

  }

  setparking(idx: any)
  {

    if (this.myBasket.artikel.length > 0) {
      this.btnGKDisabled = true;
      this.btnKassaDisabled = true;
      this.myBasket.parkingid = idx;
      this.myBasket.kassa = false;
      this.myBasket.gaestekonto = false;
      if (this.selectedGast) this.myBasket.gkid = this.selectedGast.gkid;
      this.myBasket.posGuid = this.myPosConfig.posGuid;

      this.sendParkingBasket();
    } else
    {
      this.notification.warning("Keine Artikel im Warenkorb, Beleg kann nicht geparkt werden !");
    }

  }




  SetSelectPOS(posname: string) {

    this.myPosConfig = this.PosConfigList.find(e => e.posName.toLowerCase() === posname.toLowerCase());

    if (this.myPosConfig) {

      if (this.myPosConfig.kassaTouchMode) {
        console.log('navigate to touch page');
        this.router.navigate(["touch"]);
      }

      if (!this.myPosConfig.artikel)
        this.myPosConfig.artikel = new Array<Artikel>();

      if (this.myPosConfig.artikel)
        this.myPosConfig.artikel.sort((a, b) => (a.orderId > b.orderId) ? 1 : ((b.orderId > a.orderId) ? -1 : 0));

      this.hasSelectedPOSConfig = true;
      this.posname = this.myPosConfig.posName;

      this.myBasket.posGuid = this.myPosConfig.posGuid;

      this.cfgGKDisabled = !this.myPosConfig.sendToGK;
      this.cfgKassaDisabled = !this.myPosConfig.sendToKassa;
      this.cfgBestellungDisabled = !this.myPosConfig.isBestellungPOS;

      if (this.cfgBestellungDisabled)
        this.showBestellungCol = "hidden";

      this.fillArtikelGruppen();

      this.loadGastList();

      this.setButtonState();

      this.setPOSSettings();


    } else
    {
      console.log("Error : No valid POS Found");
    }
  }

  showBtnHasBest(dataItem) {
    if (dataItem.hasBestellung === true)
      return "";
    else
      return "hidden";
  }

  showBtnHasNoBEst(dataItem) {
    if (dataItem.hasBestellung === true)
      return "hidden";
    else
      return "";

  }

  showBtnIsRepeatable(dataItem) {
    if (dataItem.repeatable === true)
      return "";
    else
      return "hidden";
  }

  showBtnIsNotRepeatable(dataItem) {
    if (dataItem.repeatable === true)
      return "hidden";
    else
      return "";
  }


  showBtnIsGuestcreated(dataItem) {

    if (dataItem.guestcreated === true)
      return "";
    else
      return "hidden";
  }

  showBtnIsNotGuestcreated(dataItem) {

    if (dataItem.guestcreated === true)
      return "hidden";
    else
      return "";
  }


  showBtnGK() {

    if (this.cfgGKDisabled === true)
      return "hidden";
    else
      return "";
  }
  showBtnKA() {

    if (this.cfgKassaDisabled === true)
      return "hidden";
    else
      return "";
  }


  //********* --> Basket Item Commands Remove Line / Decrease Count / InCrease Count / Add New Artikel

  public removeAllArtikel(dataItem) {
    if (this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBasket.artikel.indexOf(updateItem);

      this.myBasket.artikel.splice(index, 1);

      if (this.myBasket.artikel.length < 1)
        this.hasSelectedArtikel = false;

      this.notification.warning("Alle Artikel '" + updateItem.bezeichnung + "' wurde aus dem Warkorb entfernt.");
    }

    this.updateSumme();
    this.setButtonState();
  }

  public decreaseArtikelCount(dataItem) {


    if (this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBasket.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl - 1;

      if (updateItem.anzahl > 0)
        this.myBasket.artikel[index] = updateItem;
      else {
        this.myBasket.artikel.splice(index, 1);

        if (this.myBasket.artikel.length < 1)
          this.hasSelectedArtikel = false;
      }

      this.notification.warning("1 x " + updateItem.bezeichnung + " aus dem Warkorb entfernt.");
    }

    this.updateSumme();
    this.setButtonState();

  }

  public increaseArtikelCount(dataItem) {



    if (this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBasket.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBasket.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl + 1;

      this.myBasket.artikel[index] = updateItem;

      this.notification.success(updateItem.bezeichnung + " in den Warkorb hinzugefügt.");
    }

    this.updateSumme();
    this.setButtonState();

  }

  onAddArtikel(a: Artikel) {
    this.hasSelectedArtikel = true;

    if (this.myBasket.artikel.find(x => x.artikelnummer === a.artikelnummer)) {
      const updateItem = this.myBasket.artikel.find(x => x.artikelnummer === a.artikelnummer);
      const index = this.myBasket.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl + 1;

      this.myBasket.artikel[index] = updateItem;

    } else {
      a.anzahl = 1;
      this.myBasket.artikel.push(a);
    }

    this.notification.success(a.bezeichnung + " in den Warkorb hinzugefügt.");

    this.updateSumme();
    this.setButtonState();
  }

  selectedKeysChange(selectedItem: any) {
  }
  onSelect(selectedItem: any) {
  }

  cellClick(selectedItem: any) {
    this.onAddArtikel(this.myPosConfig.artikel.find(i => i.artikelnummer === selectedItem.dataItem.artikelnummer));
  }

  onSelectGroup(selectedItem: any) {
  }

  expand() {
  }


  groupChange(item: any)
  {
  }

  //********* <-- Basket Item Commands Remove Line / Decrease Count / InCrease Count / Add New Artikel


  //********* --> Gast Commands ***********************************************************

  SearchGast() {

    const suche = this.searchString.toLowerCase();
    const foundgaeste: GastDisplay[] = new Array<GastDisplay>();

    if (this.searchString.length === 0)
      this.filtergaeste = this.gaeste;

    if (this.searchString.length > 0) {
      this.gaeste.forEach(function (value) {

        if (value.vorname) {
          if (value.vorname.toLowerCase().indexOf(suche) >= 0) {

            foundgaeste.push(value);
            return;
          }
        }
        if (value.nachname) {
          if (value.nachname.toLowerCase().indexOf(suche) >= 0) {
            foundgaeste.push(value);
            return;
          }
        }
        if (value.mietobjektBezeichnung) {
          if (value.mietobjektBezeichnung.toLowerCase().indexOf(suche) >= 0) {
            foundgaeste.push(value);
            return;
          }
        }
      }
      );
    }

    if (foundgaeste.length > 0) {
      this.showGastListe = true;
      this.filtergaeste = foundgaeste;
    } else
    {
      this.showGastListe = false;
    }
  }

  selectedKeysChangeGast(selectedItem: any) {
    this.onSelectGast(this.gaeste.find(i => i.gkid === selectedItem[0]));
  }

  onSelectGast(gast: GastDisplay) {

    let PosStart = new Date(this.myPosConfig.startTime);
    let PosEnd = new Date(this.myPosConfig.endTime);

    this._abholungAmNewOrder = new Date();

    if (this.myPosConfig.offSetDefaultDay > 0)
      this._abholungAmNewOrder.setDate(this._abholungAmNewOrder.getDate() + this.myPosConfig.offSetDefaultDay);

    this._abholungAmNewOrder.setHours(PosStart.getHours(), PosStart.getMinutes(), 0, 0);



    this._min = new Date(this._abholungAmNewOrder.getFullYear(), this._abholungAmNewOrder.getMonth(), this._abholungAmNewOrder.getDay(), PosStart.getHours(), PosStart.getMinutes(), 0);
    this._max = new Date(this._abholungAmNewOrder.getFullYear(), this._abholungAmNewOrder.getMonth(), this._abholungAmNewOrder.getDay(), PosEnd.getHours(), PosEnd.getMinutes(), 0);

    this.selectedGast = gast;
    this.hasSelectedGast = true;
    this.setButtonState();
  }
  onCancelSelectedGast() {
    this.selectedGast = null;
    this.hasSelectedGast = false;
    this.setButtonState();
  }

  //********* <-- Gast Commands ***********************************************************

  setShow() {
    this.opened = true;
  }

  setHide() {
    this.opened = false;
  }

  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }

  //********* --> Basket / Order Commands *************************************************

  onCancelSelectedBasket() {
    this.myBasket.artikel = new Array<Artikel>();
    this.hasSelectedArtikel = false;
    this.updateSumme();
    this.setButtonState();
  }

  onSendBasketToGK() {
    this.btnGKDisabled = true;
    this.myBasket.kassa = false;
    this.myBasket.gaestekonto = true;
    this.myBasket.gkid = this.selectedGast.gkid;
    this.myBasket.posGuid = this.myPosConfig.posGuid;

    this.sendBasket();
  }

  onSendBasketToKassa() {
    this.btnKassaDisabled = true;
    this.myBasket.kassa = true;
    this.myBasket.gaestekonto = false;
    this.myBasket.gkid = 0;
    this.myBasket.posGuid = this.myPosConfig.posGuid;

    this.sendBasket();

  }

  onSaveOrder() {
    //Convert myBasket to Bestellung and Save
    this.btnBestellungDisabled = false;
    const bestellung: Bestellung = {} as Bestellung;
    bestellung.artikel = new Array<Artikel>();
    bestellung.artikel = this.myBasket.artikel;
    bestellung.posGuid = this.myPosConfig.posGuid;
    bestellung.gkid = this.selectedGast.gkid;
    bestellung.vorname = this.selectedGast.vorname;
    bestellung.nachname = this.selectedGast.nachname;
    bestellung.abholungAm = this._abholungAmNewOrder;
    bestellung.repeatable = this._newOrder_repeatable;
    bestellung.mietobjektBezeichnung = this.selectedGast.mietobjektBezeichnung;

    this.posService.saveBestellungen(bestellung)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this._Init();
          this.reset();
        } else {
          this.notification.error(res.errorMessage);
          this._Init();
        }

      });
  }

  //********* <-- Basket / Order Commands *************************************************

  //********* --> Bestellung Commands *****************************************************


  public onChangeRequestDate(): void {
    this.heute = false;
    this.morgen = false;
    this.alle = false;
    this.loadBestellungList();
    this.loadBackliste();
  }


  //public onChangeRequestBestellungDate(): void
  //{
  //  this.loadBestellungList();
  //}

  //public onChangeBacklisteRequestDate(): void {
  //  this.loadBackliste();
  //}

  public onAddArtikelBestellung(a: Artikel) {

    if (this.selectedBestellung.artikel.find(x => x.artikelnummer === a.artikelnummer)) {
      const updateItem = this.selectedBestellung.artikel.find(x => x.artikelnummer === a.artikelnummer);
      const index = this.selectedBestellung.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl + 1;

      this.selectedBestellung.artikel[index] = updateItem;

    } else {
      a.anzahl = 1;
      this.selectedBestellung.artikel.push(a);
    }
  }





  selectedKeysChangeBestellung(selectedItem: any) {

    if (!this.btnActionDisabledSendBestellungToGK) {
      if (!this.btnActionDisabledSendBestellungToKassa) {
        const _bestellung = this.bestellungen.find(i => i.bestellGuid === selectedItem[0]);
        this.onSelectBestellung(_bestellung);
      }
    }

  }

  onSelectBestellung(bestellung: Bestellung) {

    console.log("CALL --> onSelectBestellung");

    this.selectedBestellung = bestellung;

    this._abholungAm = new Date(bestellung.abholungAm);

    //this._min = new Date(this._abholungAm.getFullYear(), this._abholungAm.getMonth(), this._abholungAm.getDay(), 0, 0, 0);
    //this._max = new Date(this._abholungAm.getFullYear(), this._abholungAm.getMonth(), this._abholungAm.getDay(), 23, 45, 0);

    let PosStart = new Date(this.myPosConfig.startTime);
    let PosEnd = new Date(this.myPosConfig.endTime);

    this._min = new Date(this._abholungAm.getFullYear(), this._abholungAm.getMonth(), this._abholungAm.getDay(), PosStart.getHours(), PosStart.getMinutes(), 0);
    this._max = new Date(this._abholungAm.getFullYear(), this._abholungAm.getMonth(), this._abholungAm.getDay(), PosEnd.getHours(), PosEnd.getMinutes(), 0);

    this.setPOSSettings();

    this.hasSelectedBestellung = true;

  }

  _btnActionDisabledSendAllCurrentBestellungToGK(value: boolean) {
    this.btnActionDisabledSendAllCurrentBestellungToGK = value;
  }

  _btnActionDisabledSendBestellungToGK(value: boolean) {
    this.btnActionDisabledSendBestellungToGK = value;
  }

  _btnActionDisabledSendBestellungToKassa(value: boolean) {
    this.btnActionDisabledSendBestellungToKassa = value;
  }

  _btnDisabledSendBestellungToGK(value: boolean) {
    this.btnDisabledSendBestellungToGK = value;
  }

  _btnDisabledSendBestellungToKassa(value: boolean) {
    this.btnDisabledSendBestellungToKassa = value;
  }

  actionSendAllCurrentBestellungToGK() {

    this._btnActionDisabledSendAllCurrentBestellungToGK(true);

    this.bulk_bestellungen = this.bestellungen;

    if (confirm("Sollen Alle (" + this.bulk_bestellungen.length + ") Bestellungen auf die Gästekonten gebucht werden?"))
    {
      this.sendBulkBestellungToGK(true);
    }
  }

  //async _bulkBookingGK() {
  //  for (let i = 0; i < this.bulk_bestellungen.length; i++)
  //  {
  //    while (this.locked)
  //    {
  //      console.log('CALL --> [waiting 1000] bulkBookingGK islocked waiting-order : ' + this.bulk_bestellungen[i].vorname);
  //      await new Promise(done => setTimeout(() => done(null), 1000))
  //    }
  //    console.log('CALL --> bulkBookingGK for order : ' + this.bulk_bestellungen[i].vorname);
  //    this.actionSendBestellungToGK(this.bulk_bestellungen[i]);
  //  }
  //}

  actionSendBestellungToGK(item: any) {

    this.locked = true;
    this._btnActionDisabledSendBestellungToGK(true);
    this.selectedBestellung = item;
    this.sendBestellungToGK(true);
  }

  actionSendBestellungToKassa(item: any) {
    this._btnActionDisabledSendBestellungToKassa(true);
    this.selectedBestellung = item;
    this.sendBestellungToKassa(true);
  }


  sendBestellungToKassa(resetonErr: boolean) {

    this._btnDisabledSendBestellungToKassa(true);
    this.myBasket = {} as Basket;
    this.myBasket.artikel = this.selectedBestellung.artikel;
    this.myBasket.gkid = this.selectedBestellung.gkid;
    this.myBasket.posGuid = this.myPosConfig.posGuid;
    this.myBasket.kassa = true;
    this.myBasket.gaestekonto = false;

    this.posService.sendBasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {

          console.log('CALL --> sendBestellungToKassa success ! now try to delete Order item :');
          console.log(this.selectedBestellung);
          console.log('<--');

          this.notification.success(res.successMessage);

          if (this.selectedBestellung) {
            if (!this.selectedBestellung.repeatable) {
              this.deleteBestellung();
            }
            else {
              this.extendOrder();
            }
          } else {
            console.log('--> System WARNING : this.selectedBestellung is NULL');
            this.notification.warning('System Debug Info : this.selectedBestellung is NULL on delete ');
          }


          this.resetBestellungSelection();

        } else {
          this.notification.error(res.errorMessage);

          if (resetonErr) {
            this.resetBestellungSelection();
          }

        }

      });
  }


  sendBestellungToGK(resetonErr: boolean) {

    this._btnDisabledSendBestellungToGK(true);

    this.myBasket = {} as Basket;
    this.myBasket.artikel = this.selectedBestellung.artikel;
    this.myBasket.gkid = this.selectedBestellung.gkid;
    this.myBasket.posGuid = this.myPosConfig.posGuid;
    this.myBasket.kassa = false;
    this.myBasket.gaestekonto = true;

    this.posService.sendBasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {

          console.log('CALL -> sendBestellungToGK success ! now try to delete Order item :');
          console.log(this.selectedBestellung);
          console.log('<-- ');

          this.notification.success(res.successMessage);

          if (this.selectedBestellung) {
            if (!this.selectedBestellung.repeatable)
              this.deleteBestellung();
            else {
              this.extendOrder();
            }
          } else
          {
            console.log('>> this.selectedBestellung is NULL ');
            this.notification.error('System Debug Info : this.selectedBestellung is NULL on delete ');
          }

          this.resetBestellungSelection();
          
        } else {
          this.notification.error(res.errorMessage);

          if (resetonErr) {
            this.resetBestellungSelection();
          }
        }
      });
  }

  sendBulkBestellungToGK(resetonErr: boolean)
  {
    this.setShow();
    console.log("CALL --> sendBulkBestellungToGK");

    this.notification.warning("Es werden alle Bestellungen abgearbeitet, Bitte warten Sie bis die Seite neu geladen wird !");

    const request = {} as RequestBulkGKViewModel;
    request.posGuid = this.myPosConfig.posGuid;
    request.RequestDate = this.requestDate;

    this.posService.sendBulkBestellungToGK(request)
      .subscribe((res: any) => {


        this.setHide();

        if (res.success) {
          this.notification.success(res.successMessage);
          this.reset();
        } else {
          this.notification.error(res.errorMessage);
        }
        this.resetBestellungSelection();

      });
  }


  public decreaseArtikelBestellungCount(dataItem) {
    if (this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.selectedBestellung.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl - 1;

      if (updateItem.anzahl > 0)
        this.selectedBestellung.artikel[index] = updateItem;
      else {
        this.selectedBestellung.artikel.splice(index, 1);
      }
    }
  }

  public increaseArtikelBestellungCount(dataItem) {
    if (this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.selectedBestellung.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl + 1;

      this.selectedBestellung.artikel[index] = updateItem;
    }

  }

  public removeArtikelBestellung(dataItem) {
    
    if (this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.selectedBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.selectedBestellung.artikel.indexOf(updateItem);
      this.selectedBestellung.artikel.splice(index, 1);
    }
  }


  public _refreshGastList() {
    this.searchString = "";
    this.loadGastList();
  }



  //********* <-- Bestellung Commands *****************************************************

  //************ --> Service Calls *********************************

  loadGastList() {

    const request = {} as RequestGastListeViewModel;
    request.posGuid = this.myPosConfig.posGuid;
    request.posName = this.myPosConfig.posName;

    this.posService.getDisplayGast(request)
      .subscribe((res: any) => {
        this.gaeste = res;
        this.filtergaeste = res;
      });
  }

  loadPOSConfig() {
    this.posService.getAllPos()
      .subscribe((res: PosConfig[]) => {
        this.PosConfigList = res;
        if (this.posService.login) {
          this.SetSelectPOS(this.posService.login.pos);
        }
      });
  }


 

  loadArtikel() {
    this.posService.getProducts()
      .subscribe((res: Artikel[]) => {
        this.artikel = res;
      });
  }

  loadBestellungList() {

    const request = {} as RequestGetBestellungViewModel;
    request.posGuid = this.myPosConfig.posGuid;
    request.showAll = this.showAllBestellungen;
    request.RequestDate = this.requestDate;

    this.posService.getBestellungen(request)
      .subscribe((res: any) => {
        this.bestellungen = res;
        //console.log("CALL --> reload Bestellungen done with result:");
        //console.log(res);
        //console.log("<--");
      });
  }

  loadBackliste() {

    const request = {} as RequestBestellListenViewModel;
    request.posGuid = this.myPosConfig.posGuid;
    request.RequestDate = this.requestDate;

    this.posService.getTagesBestellListe(request)
      .subscribe((res: any) => {
        this.Backliste = res;
      });

    this.posService.getTagesBestellDetailListe(request)
      .subscribe((res: any) => {
        this.bestelldetailprintlist = res;
      });
  }



  saveBestellung() {

    this.selectedBestellung.abholungAm = this._abholungAm;

    this.posService.saveBestellungen(this.selectedBestellung)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.reset();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }

  sendParkingBasket()
  {
    this.posService.sendparkingbasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);

          this.reset();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }

  loadParkingBasket() {
    this.posService.loadparkingbasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          
          this.myBasket = res;

          if (this.myBasket.artikel.length > 0)
            this.hasSelectedArtikel = true;
          else
            this.hasSelectedArtikel = false;

          if (this.myBasket.gkid) {
            this.onSelectGast(this.gaeste.find(i => i.gkid === this.myBasket.gkid));
            this.hasSelectedGast = true;
          } else
          {
            this.hasSelectedGast = false;
          }

          this._hasParkinBeleg();

          this.setButtonState();
          

        } else {
          this.notification.error(res.errorMessage);
        }
      });
  }




  sendBasket() {
    this.posService.sendBasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);

          this.reset();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }

  printTagesBestellungEtiketten() {

    const request = {} as RequestBestellListenViewModel;
    request.posGuid = this.myPosConfig.posGuid;
    request.RequestDate = this.requestDate;

    this.posService.printTagesBestellungEtiketten(request)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.resetBestellungSelection();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }


  extendOrder() {
    this.posService.extendOrder(this.selectedBestellung)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.resetBestellungSelection();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }


  deleteBestellung() {
    this.posService.deleteBestellung(this.selectedBestellung)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.resetBestellungSelection();
        } else {
          this.notification.error(res.errorMessage);
        }

      });
  }

  //************ <-- Service Calls *********************************


 

  //************ Helper ****************************************

  updateSumme() {
    this.myBasket.summe = 0;

    for (const entry of this.myBasket.artikel) {
      const _brutto = Number(entry.bruttopreis);
      this.myBasket.summe = this.myBasket.summe + (entry.anzahl * _brutto);
    }
  }

  setButtonState() {
    if (!this.cfgGKDisabled) {
      //GK on POSConfig enabled
      if (this.hasSelectedArtikel && this.hasSelectedGast)
        this.btnGKDisabled = false;
      else
        this.btnGKDisabled = true;
    }
    if (!this.cfgKassaDisabled) {
      //Kassa on POSConfig enabled
      if (this.hasSelectedArtikel && !this.hasSelectedGast)
        this.btnKassaDisabled = false;
      else
        this.btnKassaDisabled = true;
    }

    if (!this.cfgBestellungDisabled) {
      //Bestellung on POSConfig enabled
      if (this.hasSelectedArtikel && this.hasSelectedGast) {
        this.btnBestellungDisabled = false;
      } else {
        this.btnBestellungDisabled = true;
      }
    }
  }

  fillArtikelGruppen() {

    for (const artikel of this.myPosConfig.artikel) {
      if (this.artikelGruppen.find(x => x.groupName === artikel.groupName)) {
        const updateItem = this.artikelGruppen.find(x => x.groupName === artikel.groupName);
        const index = this.artikelGruppen.indexOf(updateItem);

        updateItem.artikel.push(artikel);
        this.artikelGruppen[index] = updateItem;

      } else {
        const newGroup: POSGroups = {} as POSGroups;
        newGroup.groupName = artikel.groupName;
        newGroup.artikel = new Array<Artikel>();
        newGroup.artikel.push(artikel);
        this.artikelGruppen.push(newGroup);
      }
    }

    if (this.artikelGruppen.length > 1) {
      this.hasMoreArtikelGruppen = true;
      this.currentPOSGroups = this.artikelGruppen[0];
    }
    else {
      this.hasMoreArtikelGruppen = false;
      this.currentartikel = this.myPosConfig.artikel;
    }

  }

  navhome() {
    this.router.navigate(["login"]);
  }

  logout() {
    this.posService.Logout();
    this.router.navigate(["login"]);
  }

  setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  colorCode(code: string): SafeStyle {
    let result;

    switch (code) {
      case 'C1':
        result = '#FFBA80';
        break;
      case 'C2':
        result = '#B2F699';
        break;
      default:
        result = 'transparent';
        break;
    }

    return this.sanitizer.bypassSecurityTrustStyle(result);
  }

  handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.notification.error(`client-side Error Code: ${error.error.message}`);

    } else {
      // server-side error
      this.notification.error(`server-side Error Code: ${error.status}\nMessage: ${error.message}`);

    }
    this.errorReceived = true;
    return Observable.throw(error);
  }

  public onTabSelect(e) {
  }

  getAbholungClass(abholung: Date) {

    const today: Date = new Date();
    const check: Date = new Date(abholung);
    check.setDate(check.getDate() + 1);

    if (check < today)
      return "bg-warning";
  }


  changeMode() {

    //this.reset(); -- geht nicht mehr wegen Reset navhome
    this.hasSelectedGast = false;
    this.hasSelectedArtikel = false;
    this.myBasket = {} as Basket;
    this.myBasket.artikel = new Array<Artikel>();
    this.myBasket.posGuid = this.myPosConfig.posGuid;
    this.setButtonState();
    //..........

    this.resetBestellungSelection();
    this.setButtonState();

    this.modeBestellungen = !this.modeBestellungen;

    if (this.modeBestellungen) {
      this.loadBestellungList();
      this.loadBackliste();
    }
  }

  bestellListeHeute() {
    this.requestDate = new Date();

    this.heute = true;
    this.morgen = false;
    this.alle = false;

    this.showAllBestellungen = false;
    this.loadBestellungList();
    this.loadBackliste();

  }

  bestellListeMorgen() {
    const today = new Date();
    this.requestDate = new Date(today.setDate(today.getDate() + 1));

    this.heute = false;
    this.morgen = true;
    this.alle = false;


    this.showAllBestellungen = false;
    this.loadBestellungList();
    this.loadBackliste();

  }



  bestellListeAlle() {

    this.heute = false;
    this.morgen = false;
    this.alle = true;



    this.showAllBestellungen = true;
    this.loadBestellungList();
    this.loadBackliste();

  }

  resetBestellungSelection() {

    //console.log('CALL --> resetBestellungSelection current item:');
    //console.log(this.selectedBestellung);
    //console.log('<--');

    this.hasSelectedBestellung = false;
    this.selectedBestellung = null;
    this.loadBestellungList();
    this.loadBackliste();
    this.loadGastList();
    this._btnActionDisabledSendBestellungToGK(false);
    this._btnActionDisabledSendAllCurrentBestellungToGK(false);
    this._btnActionDisabledSendBestellungToKassa(false);
    this._btnDisabledSendBestellungToGK(false);
    this._btnDisabledSendBestellungToKassa(false);
    this.locked = false;
  }


  reset() {

    //this.btnKassaDisabled = false;
    //this.btnGKDisabled = false;
    this.hasSelectedGast = false;
    this.hasSelectedArtikel = false;
    this.myBasket = {} as Basket;
    this.myBasket.artikel = new Array<Artikel>();
    this.myBasket.posGuid = this.myPosConfig.posGuid;
    this.setButtonState();
    this.navhome();
  }

  //************ Helper ****************************************

  _isTypeIoT(pos: PosConfig): boolean {
    if (pos.postype.toString() === "5")
      return true;
    else
      return false;
  }

}



