import { Injectable, Inject } from '@angular/core';
import { config, Observable, Subject } from 'rxjs';
import { NotificationService } from 'src/services/notification.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Config } from 'protractor';
import { url } from 'inspector';
import { ConfigurationService } from '../services/configuration.service';
import { IConfiguration } from '../models/configuration.model';

@Injectable()
export class PosService {

  //public login: LoginResult;
  public validLogin: boolean;
  public isAdminLogin: boolean;
  public login: UserInfoModel;
  public httpOptions;

  public readerID: string;
  public autonavigate: string;
  public onlyautologin: boolean;
  public currentPOS: PosConfig;

  public autoLoginPosName: string;

  private WebServer: string;
  private APIServer: string;
  public oppurl: string;

  //private url_APIController: string;
  //private url_login: string;
  //private url_rfid_login: string;
  //private url_getposconfig: string;
  //private url_getvisibelposconfig: string;
  //private url_getProducts: string;
  //private url_deletePosConfig: string;
  //private url_savePosConfig: string;
  //private url_currentdisplaygast: string;
  //private url_getloginlog: string;
  //private url_saveBestellungen: string;
  //private url_sendBasket: string;
  //private url_deleteBestellung: string;
  //private url_extendOrder: string;
  //private url_printtodaygesamtbestellung: string;
  //private url_gettodaygesamtbestellung: string;
  //private url_gettodaygesamtbestellungdetail: string;
  //private url_getbestellung: string;
  //private url_getuserbestellung: string;
  //private url_getgeastekonto: string;
  //private url_getPayment: string;
  //private url_getPaymentState: string;
  //private url_getrechnungcontent: string;
  //private url_getimage: string;
  //private url_getcontent: string;
  //private url_getreader: string;
  //private url_updatereaderstate: string;
  //private url_SchrankeController: string;
  //private url_open_gate: string;
  //private url_close_gate: string;
  //private url_buy_device: string;
  //private url_release_device: string;

  //********* TEST VM INTERN *******************
  //WebServer = 'https://test-win-10:5001';
  //APIServer = 'https://test-win-10:6001/';
  //public oppurl = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=';
  //**************************************


  //**********LOKAL DEV **************************
  //Server = 'https://localhost:44315';
  //baseurl = 'https://localhost:6001/';
  //public oppurl = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=';
  ////*****************************************

  //********* TEST UX Intern *******************
  //Server = 'https://ktech-ux-server1-test:5001';
  //baseurl = 'https://ktech-ux-server1-test:6001/';
  //public oppurl = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=';
  //**************************************

  
  //********* DEMO VM Extern *******************
  //Server = 'https://demo-service.ktech.at';
  //baseurl = 'https://demo-api.ktech.at/';
  //public oppurl = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=';
  //**************************************


  //********** PROD OK *******************
  //Server = 'https://service.topcamp.at';
  //baseurl = 'https://api.topcamp.at/';
  //public oppurl = 'https://oppwa.com/v1/paymentWidgets.js?checkoutId=';
  //********** PROD OK *******************

  
  //********* BER UX Externn *******************
  //Server = 'https://service.camping-bergheimat.de';
  //baseurl = 'https://api.camping-bergheimat.de/';
  //public oppurl = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=';
  //**************************************

  //********* AU UX Intern *******************
  //Server = 'https://service.camping-audonau.at/';
  //baseurl = 'https://api.camping-audonau.at/';
  //public oppurl = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=';
  //**************************************


  //********* STU UX Externn *******************
  //Server = 'https://service.stumpfer.com';
  //baseurl = 'https://api.stumpfer.com/';
  //public oppurl = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=';
  //**************************************
  
  

  constructor(private http: HttpClient, private notify: NotificationService,private configurationService: ConfigurationService)
  {
    this._init();

    this.validLogin = false;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
      })
    };
  }

  _init()
  {
    console.log('bevor init');
    const baseURI = document.baseURI!.endsWith('/') ? document.baseURI : `${document.baseURI}/`;
    let url = `${baseURI}assets/json-config/posSettings.json`;
   
    var request = new XMLHttpRequest();
    request.open('GET', url, false);
    request.send(null);
    const response = JSON.parse(request.responseText);

    this.configurationService.serverSettings = {} as IConfiguration;
    this.configurationService.serverSettings.APIServer = response.APIServer;
    this.configurationService.serverSettings.WebServer = response.WebServer;
    this.configurationService.serverSettings.SmartEServer = response.SmartEServer;
    this.configurationService.serverSettings.oppurl = response.oppurl;

    console.log(this.configurationService.serverSettings);
    console.log('after init');
    
  }

  //*******************************************************************************
  // ************* SMART ENERGY ***************************************************

  getSmartEnergy(channel:string)
  {
    //this.http.get<SmartMeter[]>(this.configurationService.serverSettings.SmartEServer + '', this.httpOptions);
    let url = this.configurationService.serverSettings.SmartEServer + 'smartenergy/api/v1/CurrentSmartMeter/' + channel;
    return this.http.post(url, this.httpOptions)
  }

  //
  getSmartEnergyDetail(request: RequestSmartMeterValueDetails) {
    //this.http.get<SmartMeter[]>(this.configurationService.serverSettings.SmartEServer + '', this.httpOptions);
    let url = this.configurationService.serverSettings.SmartEServer + 'smartenergy/api/v1/CurrentDetailSmartMeter';
    return this.http.post(url, request, this.httpOptions)
  }
  

  //*******************************************************************************

  openGate() {
    let url = this.configurationService.serverSettings.APIServer + 'api/schranke/public/open_gate';
    return this.http.post(url, this.httpOptions)
  }
  closeGate() {
    let url = this.configurationService.serverSettings.APIServer + 'api/schranke/public/close_gate';
    return this.http.post(url, this.httpOptions)
  }
  //*******************************************************************************

  
  buyDevice(device: Device) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/buy';
    return this.http.post(url, device, this.httpOptions)
  }
  releaseDevice(device: Device) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/release';
    return this.http.post(url, device, this.httpOptions)
  }
  //*******************************************************************************



  Login(loginReq: LoginRequestModel)
  {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/login';
    return this.http.post(url, loginReq, this.httpOptions)
  }

  rfidLogin(loginReq: LoginRequestModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/rfidlogin';
    return this.http.post(url, loginReq, this.httpOptions)
  }

  getReaderInfo(readerReq: LoginRequestModel)
  {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getreader';
    return this.http.post(url, readerReq, this.httpOptions)
  }

  updateReaderState(readerReq: LoginRequestModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/updatereaderstate';
    return this.http.post(url, readerReq, this.httpOptions)
  }

  getAllPos() {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getposconfig';
    return this.http.post(url, this.httpOptions)
  }

  sendparkingbasket(basket: Basket) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/sendparkingbasket';
    return this.http.post(url, basket, this.httpOptions)
  }

  loadparkingbasket(basket: Basket) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/loadparkingbasket';
    return this.http.post(url, basket, this.httpOptions)
  }

  hasparkingbasket(basket: Basket) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/hasparkingbasket';
    return this.http.post(url, basket, this.httpOptions)
  }
  


  getVisibelPos() {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getvisibelposconfig';
    return this.http.post(url, this.httpOptions)
  }

  getLoginLog() {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getLoginLog';
    return this.http.post(url, this.httpOptions)
  }

  getProducts() {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/products';
    return this.http.post(url, this.httpOptions)
  }

  setKWHArtikel(request: RequestKWHArtikelViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/setkwhartikel';
    return this.http.post(url, request, this.httpOptions)
  }

  getGeastekonto(request: RequestGaestekontoViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getgeastekonto';
    return this.http.post(url, request, this.httpOptions)
  }

  getPayment(request: RequestGaestekontoViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getpayment';
    return this.http.post(url, request, this.httpOptions)
  }

  getPaymentState(request: RequestPaymentViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getpaymentstate';
    return this.http.post(url, request, this.httpOptions)
  }

  getDisplayGast(request: RequestGastListeViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/currentdisplaygast';
    return this.http.post(url, request, this.httpOptions)
  }


  savePosConfig(poscfg: PosConfig) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/saveposconfig';
    return this.http.post(url, poscfg, this.httpOptions)
  }

  deletePosConfig(poscfg: PosConfig) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/deleteposconfig';
    return this.http.post(url, poscfg, this.httpOptions)
  }

  saveBestellungen(bestellung: Bestellung) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/savebestellungen';
    return this.http.post(url, bestellung, this.httpOptions)
  }

  sendBasket(basket: Basket) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/sendbasket';
    return this.http.post(url, basket, this.httpOptions)
  }


  deleteBestellung(bestellung: Bestellung) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/deletebestellung';
    return this.http.post(url, bestellung, this.httpOptions)
  }

  extendOrder(bestellung: Bestellung) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/extendOrder';
    return this.http.post(url, bestellung, this.httpOptions)
  }


  printTagesBestellungEtiketten(request: RequestBestellListenViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/printtodaygesamtbestellung';
    return this.http.post(url, request, this.httpOptions);
  }

  getTagesBestellListe(request: RequestBestellListenViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/gettodaygesamtbestellung';
    return this.http.post(url, request, this.httpOptions)
  }

  getTagesBestellDetailListe(request: RequestBestellListenViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/gettodaygesamtbestellungdetail';
    return this.http.post(url, request, this.httpOptions)
  }

  getBestellungen(request: RequestGetBestellungViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getbestellung';
    return this.http.post(url, request, this.httpOptions)
  }

  sendBulkBestellungToGK(request: RequestBulkGKViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/sendbulkbesellung';
    return this.http.post(url, request, this.httpOptions)
  }



  getUserBestellungen(request: RequestGetBestellungViewModel) {
    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getuserbestellung';
    return this.http.post(url, request, this.httpOptions)
  }


  public getPDF(request: RequestGaestekontoViewModel) {

    let url = this.configurationService.serverSettings.APIServer + 'api/pos/public/getrechnungcontent';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
      })
    };
    return this.http.post(url, request, httpOptions).pipe(
      retry(1)
    );
  }


  Logout() {
    console.log("User Logout");
    this.validLogin = false;
    this.isAdminLogin = false;
    this.login = {} as UserInfoModel;
  }

  getDateString(Date: Date) {
    return Date.toLocaleDateString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' });
  }

}
