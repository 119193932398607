import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PosService } from 'src/services/pos.services';
import { NotificationService } from 'src/services/notification.service';
import { AppConfig } from 'src/app/config/app.config';
import { TranslateService } from '@ngx-translate/core';
import { Day } from '@progress/kendo-date-math';
import { interval, Subscription } from 'rxjs'
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

declare function pos_resize(): any;

@Component({
  selector: 'app-home',
  templateUrl: './bestellung.component.html',
})
export class BestellungComponent implements AfterViewInit {

  public myPosConfig: PosConfig;
  public productdetail: string;
  public PosConfigList: PosConfig[];
  public hasSelectedPOSConfig: boolean;
  public POSName: string;
  public myBestellung: Bestellung;
  public _abholungAm: Date;
  public _min: Date;
  public _max: Date;
  public _minDate: Date;
  public _maxDate: Date;
  public steps: any = { hour: 1, minute: 15 };
  public disabledDays: Day[];
  //------------------------------
  //public POSName: string;
  public POSOrderButton: string;
  public POSAddButton: string;
  public POSHeader: string;
  public POSDescription: string;
  public POSClosedDescription: string;
  public POSOrderExist: string;
  public POSPickUpOn: string;
  public POSPickUpAt: string;
  public POSOrderHeader: string;

  public POSLabelProductDescription: string;
  public POSLabelNumber: string;
  public POSLabelSingelPrice: string;
  public POSLabelTotalPrice: string;
  private showInfo: boolean;

  public duration: number;
  public type: string;
  public direction: string;
  public enabled: boolean;

  public opened = false;

  //************* Artikel Gruppen ****************
  public hasMoreArtikelGruppen: boolean;
  public artikelGruppen: POSGroups[];
  public currentPOSGroup: POSGroups;
  //************* Artikel Gruppen ****************
  private showGoodbye: boolean;
  public myBasket: Basket;

  private sub1: Subscription;
  private sub2: Subscription;
  private sub3: Subscription;
  private sub4: Subscription;
  private sub5: Subscription;


  //.................
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  //.................


  constructor(private router: Router, private posService: PosService, private notification: NotificationService, private idle: Idle, private keepalive: Keepalive, private _config: AppConfig, public translate: TranslateService) {

    if (!posService.validLogin) {
      console.log("no valid Login found -> navigate to logon page");
      this.router.navigate(["login"]);
    }

    this.init();

    this.setText();

    this.setPOSSettings();

    if (this.posService.currentPOS)
      if (this.posService.currentPOS.kioskmode)
        this.idlefunction(this.posService.currentPOS.autologouttime_idel);
  }

  //***************************************
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }


  //********************* POP UP***********
  public onToggle(a: Artikel): void {

    this.setDetailInfo(a);
    this.showInfo = !this.showInfo;
  }

  public get animate(): any {
    if (this.enabled) {
      return {
        type: this.type,
        direction: this.direction,
        duration: this.duration
      };
    }

    return false;
  }

  public get hasDirection(): boolean {
    return this.type === 'slide' || this.type === 'expand';
  }

  //*************************************** AUTO LOGOUT *************
  idlefunction(idelseconds: number) {

    if (this.sub1)
      this.sub1.unsubscribe();
    if (this.sub2)
      this.sub2.unsubscribe();
    if (this.sub3)
      this.sub3.unsubscribe();
    if (this.sub4)
      this.sub4.unsubscribe();
    if (this.sub5)
      this.sub5.unsubscribe();


    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(idelseconds);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.sub1 = this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    this.sub2 = this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      //this.router.navigate(['/']);
      this.logout();
    });

    this.sub3 = this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);

      //this.childModal.show();

    });

    this.sub4 = this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      //this.notification.warning(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.sub5 = this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnDestroy() {
    if (this.posService.currentPOS) {
      if (this.posService.currentPOS.kioskmode) {

        console.log(">> ngOnDestroy");

        this.sub1.unsubscribe();
        this.sub2.unsubscribe();
        this.sub3.unsubscribe();
        this.sub4.unsubscribe();
        this.sub5.unsubscribe();

        console.log("<< ngOnDestroy");
      }
    }
  }


  //*****************************************************************


  setText() {

    //
    const POSOrderButton_key = this.POSName + '.OrderButton';
    this.translate.get(POSOrderButton_key).subscribe((res) => {

      if (res === POSOrderButton_key)
        this.POSOrderButton = "Kein " + POSOrderButton_key + " Text gefunden";
      else
        this.POSOrderButton = res;
    });

    //
    const POSAddbutton_key = this.POSName + '.AddButton';
    this.translate.get(POSAddbutton_key).subscribe((res) => {

      if (res === POSAddbutton_key)
        this.POSAddButton = "Kein " + POSAddbutton_key + " Text gefunden";
      else
        this.POSAddButton = res;
    });

    //
    const POSHeader_key = this.POSName + '.POSHeader';
    this.translate.get(POSHeader_key).subscribe((res) => {

      if (res === POSHeader_key)
        this.POSHeader = "Kein" + POSHeader_key + " Text gefunden";
      else
        if (res.indexOf('#GAST#') >= 0) {

          if (this.posService.login)
            this.POSHeader = res.replace('#GAST#', this.posService.login.displayName);
          else
            this.POSHeader = res;

        } else {

          this.POSHeader = res;
        }

    });

    //
    const POSOrderExist_key = this.POSName + '.OrderExist';
    this.translate.get(POSOrderExist_key).subscribe((res) => {

      if (res === POSOrderExist_key)
        this.POSOrderExist = "Kein " + POSOrderExist_key + "Text gefunden";
      else
        if (res.indexOf('#ORDERDATE#') >= 0) {

          if (this._abholungAm) {
            const datestring = this._abholungAm.getDate() + '.' + (this._abholungAm.getMonth() + 1) + '.' + this._abholungAm.getFullYear();
            this.POSOrderExist = res.replace('#ORDERDATE#', datestring);
          }
        } else {

          this.POSOrderExist = res;
        }

    });

    //
    const POSDescription_key = this.POSName + '.POSDescription';
    this.translate.get(POSDescription_key).subscribe((res) => {

      if (res === POSDescription_key)
        this.POSDescription = "Kein " + POSDescription_key + " gefunden";
      else
        if (res.indexOf('#GAST#') >= 0) {

          if (this.posService.login)
            this.POSDescription = res.replace('#GAST#', this.posService.login.displayName);
          else
            this.POSDescription = res;
        } else {

          this.POSDescription = res;
        }
    });

    //
    const POSClosedDescription_key = this.POSName + '.POSClosedDescription';
    this.translate.get(POSClosedDescription_key).subscribe((res) => {

      if (res === POSClosedDescription_key)
        this.POSClosedDescription = "Kein " + POSClosedDescription_key + " gefunden";
      else
        if (res.indexOf('#GAST#') >= 0) {

          if (this.posService.login)
            this.POSClosedDescription = res.replace('#GAST#', this.posService.login.displayName);
          else
            this.POSClosedDescription = res;
        } else {

          this.POSClosedDescription = res;
        }
    });

    //
    const POSPickUpOn_key = this.POSName + '.PickUpOn';
    this.translate.get(POSPickUpOn_key).subscribe((res) => {

      if (res === POSPickUpOn_key)
        this.POSPickUpOn = "Kein " + POSPickUpOn_key + " Text gefunden";
      else
        this.POSPickUpOn = res;
    });

    //
    const POSPickUpAt_key = this.POSName + '.PickUpAt';
    this.translate.get(POSPickUpAt_key).subscribe((res) => {

      if (res === POSPickUpAt_key)
        this.POSPickUpAt = "Kein " + POSPickUpAt_key + " Text gefunden";
      else
        this.POSPickUpAt = res;
    });

    //
    const POSOrderHeader_key = this.POSName + '.OrderHeader';
    this.translate.get(POSOrderHeader_key).subscribe((res) => {

      if (res === POSOrderHeader_key)
        this.POSOrderHeader = "Kein " + POSOrderHeader_key + " gefunden";
      else
        if (res.indexOf('#GAST#') >= 0) {

          if (this.posService.login)
            this.POSOrderHeader = res.replace('#GAST#', this.posService.login.displayName);
          else
            this.POSOrderHeader = res;

        } else {

          this.POSOrderHeader = res;
        }
    });

    //
    const POSLabelProductDescription_key = this.POSName + '.ColumnLabelProductDescription';
    this.translate.get(POSLabelProductDescription_key).subscribe((res) => {

      if (res === POSLabelProductDescription_key)
        this.POSLabelProductDescription = "Kein " + POSLabelProductDescription_key + " Text gefunden";
      else
        this.POSLabelProductDescription = res;
    });

    //
    const POSLabelNumber_key = this.POSName + '.ColumnLabelNumber';
    this.translate.get(POSLabelNumber_key).subscribe((res) => {

      if (res === POSLabelNumber_key)
        this.POSLabelNumber = "Kein " + POSLabelNumber_key + " Text gefunden";
      else
        this.POSLabelNumber = res;
    });

    //
    const POSLabelSingelPrice_key = this.POSName + '.ColumnLabelSingelPrice';
    this.translate.get(POSLabelSingelPrice_key).subscribe((res) => {

      if (res === POSLabelSingelPrice_key)
        this.POSLabelSingelPrice = "Kein " + POSLabelSingelPrice_key + " Text gefunden";
      else
        this.POSLabelSingelPrice = res;
    });

    //
    const POSLabelTotalPrice_key = this.POSName + '.ColumnLabelTotalPrice';
    this.translate.get(POSLabelTotalPrice_key).subscribe((res) => {

      if (res === POSLabelTotalPrice_key)
        this.POSLabelTotalPrice = "Kein " + POSLabelTotalPrice_key + " Text gefunden";
      else
        this.POSLabelTotalPrice = res;
    });
  }


  _getPOSOrderButtonText(): string {
    return this.POSOrderButton;

  }
  _getPOSAddButtonText(): string {
    return this.POSAddButton;

  }
  _getPOSDescription(): string {
    return this.POSDescription;
  }

  _getPOSHeader(): string {
    return this.POSHeader;
  }

  _getPOSClosedDescription(): string {
    return this.POSClosedDescription;
  }

  _getPOSOrderExist(): string {
    return this.POSOrderExist;
  }

  _getPOSPickUpOn(): string {
    return this.POSPickUpOn;
  }

  _getPOSPickUpAt(): string {
    return this.POSPickUpAt;
  }

  _getPOSOrderHeader(): string {
    return this.POSOrderHeader;
  }

  _getPOSLabelLabelNumber(): string {
    return this.POSLabelNumber;
  }

  _getPOSLabelProductDescription(): string {
    return this.POSLabelProductDescription;
  }

  _getPOSLabelSingelPrice(): string {
    return this.POSLabelSingelPrice;
  }

  _getPOSLabelTotalPrice(): string {
    return this.POSLabelTotalPrice;
  }


  _changeLan(lang: string) {
    this.translate.use(lang);
    this._config.useLang(lang);
    this.setText();
  }


  ngAfterViewInit() {
  }


  init() {
    //.....
    this.type = 'expand';
    this.duration = 500;
    this.direction = "right";
    this.enabled = true;
    //.....
    this.showInfo = false;


    this.productdetail = "";
    this.myPosConfig = {} as PosConfig;
    this.PosConfigList = {} as PosConfig[];
    this.disabledDays = null;
    this.hasSelectedPOSConfig = false;

    this.myPosConfig = this.posService.currentPOS;
    this.hasMoreArtikelGruppen = false;

    this.POSName = this.myPosConfig.posName;//"Gebäck";
    this.myBestellung = {} as Bestellung;
    this.myBestellung.artikel = new Array<Artikel>();
    this.artikelGruppen = new Array<POSGroups>();
    this.loadBestellung();
    this._minDate = new Date();
    this._maxDate = null;
    this.showGoodbye = false;
    this.setdisabledDays();

    this.myBasket = {} as Basket;
    this.myBasket.artikel = new Array<Artikel>();

    this.fillArtikelGruppen();

  }


  setPOSSettings()
  {
    if (this.myPosConfig)
    {
      if (!this.myPosConfig.intervall_size)
        return;

      if (this.myPosConfig.intervall_size.toString() === "0") // 15min default
        return;

      if (this.myPosConfig.intervall_size.toString() === "1") // 30min default
        this.steps = { hour: 1, minute: 30 };

      if (this.myPosConfig.intervall_size.toString() === "2") // 60min default
        this.steps = { hour: 1, minute: 60 };

    }
  }

  //loadPOS()
  //{
  //  this.posService.getAllPos()
  //    .subscribe((res: PosConfig[]) => {

  //      this.PosConfigList = res;

  //      if (this.PosConfigList.find(x => x.posName === this.POSName))
  //      {
  //        this.myPosConfig = this.PosConfigList.find(x => x.posName === this.POSName);
  //        this.loadBestellung();
  //      }
  //    });
  //}

  addItem(a: Artikel) {
    console.log(a);
  }

  setdisabledDays() {

    if (this.myPosConfig) {

      if (this.myPosConfig.useStartEndOrderDate) {
        this._minDate = new Date(this.myPosConfig.startOrderDate);
        this._maxDate = new Date(this.myPosConfig.endOrderDate);

      } else if (this.myPosConfig.usefixOrderDate) {
        this._minDate = new Date(this.myPosConfig.fixOrderDate);
        this._maxDate = new Date(this.myPosConfig.fixOrderDate);
      }


      if (!this.myPosConfig.monday)
        this._addDisabledDay(Day.Monday);
      if (!this.myPosConfig.tuesday)
        this._addDisabledDay(Day.Tuesday);
      if (!this.myPosConfig.wednesday)
        this._addDisabledDay(Day.Wednesday);
      if (!this.myPosConfig.thursday)
        this._addDisabledDay(Day.Thursday);
      if (!this.myPosConfig.friday)
        this._addDisabledDay(Day.Friday);
      if (!this.myPosConfig.saturday)
        this._addDisabledDay(Day.Saturday);
      if (!this.myPosConfig.sunday)
        this._addDisabledDay(Day.Sunday);

    }
  }

  _addDisabledDay(_day: Day) {
    if (!this.disabledDays)
      this.disabledDays = new Array<Day>();

    this.disabledDays.push(_day);
  }


  loadBestellung() {

    const request = {} as RequestGetBestellungViewModel;
    request.posGuid = this.myPosConfig.posGuid;

    if (this.posService && this.posService.login)
      request.gkid = this.posService.login.gkid;

    this.posService.getUserBestellungen(request)
      .subscribe((res: any) => {
        this.myBestellung = res;

        let PosStart = new Date(this.myPosConfig.startTime);
        let PosEnd = new Date(this.myPosConfig.endTime);

        this._abholungAm = new Date(this.myBestellung.abholungAm);

        this._min = new Date(this._abholungAm.getFullYear(), this._abholungAm.getMonth(), this._abholungAm.getDay(), PosStart.getHours(), PosStart.getMinutes(), 0);
        this._max = new Date(this._abholungAm.getFullYear(), this._abholungAm.getMonth(), this._abholungAm.getDay(), PosEnd.getHours(), PosEnd.getMinutes(), 0);

        this.updateSumme();

        pos_resize();
        this.setText();

      });

  }

  updateSumme() {
    if (this.myBestellung) {
      this.myBestellung.summe = 0;
      this.myBestellung.summenetto = 0;
      this.myBestellung.summetax = 0;
      for (const entry of this.myBestellung.artikel) {

        this.myBestellung.summe += entry.gesamt;
        this.myBestellung.summenetto += entry.gesamtnetto;
        this.myBestellung.summetax += entry.gesamttaxe;
      }
    }
  }

  public decreaseArtikelBestellungCount(dataItem) {

    if (this.myBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBestellung.artikel.indexOf(updateItem);

      updateItem.anzahl = updateItem.anzahl - 1;
      updateItem.gesamt = updateItem.anzahl * Number(updateItem.bruttopreis);

      updateItem.gesamtnetto = updateItem.anzahl * Number(updateItem.nettopreis);
      updateItem.gesamttaxe = updateItem.gesamt - updateItem.gesamtnetto;

      if (updateItem.anzahl > 0)
        this.myBestellung.artikel[index] = updateItem;
      else {
        this.myBestellung.artikel.splice(index, 1);
      }
    }
    this.updateSumme();
  }

  increaseArtikelBestellungCountGen(dataItem) {

    if (dataItem.anzahl < 1) {
      this.notification.warning("Ungültige Eingabe bei Artikelanzahl " + dataItem.anzahl);
      return;
    }


    if (this.myBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBestellung.artikel.indexOf(updateItem);

      //check nur wenn POS auf Use Limit geschalten ist
      if (this.myPosConfig.uselimit) {
        if (dataItem.uselimit) {
          if (updateItem.anzahl + dataItem.anzahl > updateItem.oderlimit) {
            this.notification.warning("Bestelllimit für " + dataItem.bezeichnung + " überschritten. Es können maximal " + updateItem.oderlimit + " bestellt werden.");
            return;
          }
        }
      }

      updateItem.anzahl = updateItem.anzahl + dataItem.anzahl;

      updateItem.gesamt = updateItem.anzahl * Number(updateItem.bruttopreis);
      updateItem.gesamtnetto = updateItem.anzahl * Number(updateItem.nettopreis);
      updateItem.gesamttaxe = updateItem.gesamt - updateItem.gesamtnetto;

      this.myBestellung.artikel[index] = updateItem;

      this.notification.success(dataItem.anzahl + " " + dataItem.bezeichnung + " in den Warenkorb hinzugefügt");


    } else {
      //check nur wenn POS auf Use Limit geschalten ist
      if (this.myPosConfig.uselimit) {
        if (dataItem.uselimit) {
          if (dataItem.anzahl > dataItem.oderlimit) {
            this.notification.warning("Bestelllimit für " + dataItem.bezeichnung + " überschritten. Es können maximal " + dataItem.oderlimit + " bestellt werden.");
            return;
          }
        }
      }


      dataItem.gesamt = dataItem.anzahl * Number(dataItem.bruttopreis);
      this.myBestellung.artikel.push(this.simpleClone(dataItem));
      this.notification.success(dataItem.anzahl + " " + dataItem.bezeichnung + " in den Warenkorb hinzugefügt");
    }

    this.updateSumme();
  }

  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }


  public increaseArtikelBestellungCount(dataItem) {

    if (this.myBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBestellung.artikel.indexOf(updateItem);
      updateItem.anzahl = updateItem.anzahl + 1;

      updateItem.gesamt = updateItem.anzahl * Number(updateItem.bruttopreis);
      updateItem.gesamtnetto = updateItem.anzahl * Number(updateItem.nettopreis);
      updateItem.gesamttaxe = updateItem.gesamt - updateItem.gesamtnetto;

      this.myBestellung.artikel[index] = updateItem;


      this.notification.success("1 " + dataItem.bezeichnung + " in den Warenkorb hinzugefügt");


    } else {
      this.myBestellung.artikel.push(dataItem);
      this.notification.success("1 " + dataItem.bezeichnung + " in den Warenkorb hinzugefügt");
    }

    this.updateSumme();
  }

  public removeArtikelBestellung(dataItem) {
    console.log('removeArtikelBestellung ' + dataItem);

    if (this.myBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer)) {
      const updateItem = this.myBestellung.artikel.find(x => x.artikelnummer === dataItem.artikelnummer);
      const index = this.myBestellung.artikel.indexOf(updateItem);
      this.myBestellung.artikel.splice(index, 1);

      this.notification.warning(dataItem.bezeichnung + " aus den Warenkorb gelöscht");
    }

    this.updateSumme();

  }

  //************* Artikel Gruppen ****************
  fillArtikelGruppen() {

    console.log('fillArtikelGruppen');
    for (const artikel of this.myPosConfig.artikel) {
      if (this.artikelGruppen.find(x => x.groupName === artikel.groupName)) {
        const updateItem = this.artikelGruppen.find(x => x.groupName === artikel.groupName);
        const index = this.artikelGruppen.indexOf(updateItem);

        updateItem.artikel.push(artikel);
        this.artikelGruppen[index] = updateItem;

      } else {
        const newGroup: POSGroups = {} as POSGroups;
        newGroup.groupName = artikel.groupName;
        newGroup.artikel = new Array<Artikel>();
        newGroup.artikel.push(artikel);
        this.artikelGruppen.push(newGroup);
      }
    }

    if (this.artikelGruppen.length > 1) {
      this.hasMoreArtikelGruppen = true;
    }
    else {
      this.hasMoreArtikelGruppen = false;
    }
   
  }

  navartilelGruppe(group : POSGroups)
  {
    console.log('Nav Gruppe:');
    console.log(group);
    this.currentPOSGroup = group;
  }

  _getImageName(group: POSGroups): string {
    return "../../assets/Images/" + group.groupName + ".jpg";
  }

  //**********************************************

  setDetailInfo(a: Artikel) {
    this.opened = true;

    this.productdetail = a.produktDetailBeschreibung;
  }

  setDetailInfoOverlay(a: Artikel) {
    this.productdetail = a.produktDetailBeschreibung;
  }




  clearDetailInfo() {
    this.productdetail = "";
  }

  senddirectGK()
  {
    if(this.myBestellung.artikel.length > 0)
      this.sendBestellungToGK(true);
    else
      this.notification.warning("Keine Artikel ausgewählt");
  }

  sendBestellungToGK(resetonErr: boolean) {


    this.myBasket = {} as Basket;
    this.myBasket.artikel = this.myBestellung.artikel;
    this.myBasket.gkid = this.myBestellung.gkid;

    this.myBasket.kassa = false;
    this.myBasket.gaestekonto = true;

    this.posService.sendBasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {

          this.notification.success(res.successMessage);
          this.resetBestellungSelection();

          this.showGoodbye = true;
          this.idlefunction(this.posService.currentPOS.autologouttime_buy);

        } else {
          this.notification.error(res.errorMessage);

          if (resetonErr) {
            this.resetBestellungSelection();
          }
        }

      });
  }

  resetBestellungSelection()
  {
    //Reserved  
  }





  sendOrder() {

    this.myBestellung.abholungAm = this._abholungAm;
    this.myBestellung.guestcreated = true;
    if (this.posService.login) {
      this.myBestellung.mietobjektBezeichnung = this.posService.login.mietobjekt;
    }

    this.posService.saveBestellungen(this.myBestellung)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.navwelcome();
        } else {
          this.notification.warning(res.errorMessage);
        }
      });
  }

  navResetCurrentArtikelGroup()
  {
    this.currentPOSGroup = null;
  }
  navhome() {
    
    if (this.posService.currentPOS.kioskmode)
    {
    
      this.posService.Logout();
      this.router.navigate(["login"], { queryParams: { readerID: this.posService.readerID, autonavigate: this.posService.autonavigate, onlyautologin: this.posService.onlyautologin, autoLoginPosName: this.posService.autoLoginPosName}, queryParamsHandling: "merge" });

    } else {
      this.router.navigate(["welcome"]);
    }

  }

  navwelcome() {

    if (this.posService.currentPOS.kioskmode)
    {
      this.posService.Logout();
      this.router.navigate(["login"], { queryParams: { readerID: this.posService.readerID, autonavigate: this.posService.autonavigate, onlyautologin: this.posService.onlyautologin, autoLoginPosName: this.posService.autoLoginPosName }, queryParamsHandling: "merge" });

    } else {
      this.router.navigate(["welcome"]);
    }


  }

  logout() {
    
    if (this.posService.currentPOS.kioskmode)
    {
      this.posService.Logout();
      this.router.navigate(["login"], { queryParams: { readerID: this.posService.readerID, autonavigate: this.posService.autonavigate, onlyautologin: this.posService.onlyautologin, autoLoginPosName: this.posService.autoLoginPosName }, queryParamsHandling: "merge" });
    } else
    {
      this.posService.Logout();
      this.router.navigate(["login"]);
    }
  }

  _disabled() {
    if (this.myBestellung.neworder)
      return 'disabled';
    else
      return 'disabled';
  }
}
