import { Component, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PosService } from 'src/services/pos.services';
import { AppConfig } from 'src/app/config/app.config';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './welcome.component.html',
})
export class WelcomeComponent  {

  private DisplayGastData: string;

  private posname: string;
  private welcomePageDiscription: string;
  private welcomePageHeader: string;
  private homepageCardTitel: string;
  private gaestekontoCardTitel: string;
  private posConfigList: PosConfig[];
  private image: any;
  private displayImage: any;

  constructor(private router: Router, private posService: PosService, private _config: AppConfig, public translate: TranslateService) {

    if (!posService.validLogin) {
      console.log("no valid Login found -> navigate to logon page");
      this.router.navigate(["login"]);
    }

    this._init();
    this._loadPOS();
    this._setText();
  }

  


  _init()
  {
    this.posConfigList = new Array<PosConfig>();
  }


  _setText() {
    //... template muss geändert werden für jedes POS
    const posCardTitel_key = 'Gebäck.WelcomePageCardTitel';

    const gaestekontoCardTitel_key = 'Gästekonto.WelcomePageCardTitel';
    const homepageCardTitel_key = 'Homepage.WelcomePageCardTitel';

    const welcomePageHeader_key = 'Welcomepage.Header';
    const welcomePageDiscription_key = 'Welcomepage.Description';

    //für jedes POS notwendig .. ? methode mit POS item übergabe aber wie bei sprachänderung ?
    this.translate.get(posCardTitel_key).subscribe((res) => {
      if (res === posCardTitel_key)
        this.posname = "Kein POS Name gefunden";
      else
        this.posname = res;

    });

    //move this to a helper function ?
    this.translate.get(welcomePageHeader_key).subscribe((res) => {

      if (res === welcomePageHeader_key)
        this.welcomePageHeader = "Kein Header Text gefunden";
      else
        this.welcomePageHeader = res;

    });

    this.translate.get(gaestekontoCardTitel_key).subscribe((res) => {

      if (res === gaestekontoCardTitel_key)
        this.gaestekontoCardTitel = "Kein GK Text gefunden";
      else
        if (res.indexOf('#GAST#') >= 0) {

          if (this.posService.login)
            this.gaestekontoCardTitel = res.replace('#GAST#', this.posService.login.displayName);
          else
            this.gaestekontoCardTitel = res;

        } else {

          this.gaestekontoCardTitel = res;
        }

    });

   


    this.translate.get(homepageCardTitel_key).subscribe((res) => {

      if (res === homepageCardTitel_key)
        this.homepageCardTitel = "Kein homepage Text gefunden";
      else
        this.homepageCardTitel = res;

    });





    this.translate.get(welcomePageDiscription_key).subscribe((res) => {
      if (res === welcomePageDiscription_key)
        this.welcomePageDiscription = "Kein Text für " + welcomePageDiscription_key + " gefunden";
      else {
        if (res.indexOf('#GAST#') >= 0) {

          if (this.posService.login)
            this.welcomePageDiscription = res.replace('#GAST#', this.posService.login.displayName);
          else
            this.welcomePageDiscription = res;

        } else {

          this.welcomePageDiscription = res;
        }
      }
    });
  }

  _loadPOS()
  {
      this.posService.getVisibelPos()
        .subscribe((res: PosConfig[]) => {
          this.posConfigList = res;
        });
  }

  //if(pos.postype.toString() === "0") //GK
  //if (pos.postype.toString() === "1") //link
  //if (pos.postype.toString() === "2") //intern
  //if (pos.postype.toString() === "3") //public
  //if (pos.postype.toString() === "4") //info

  _isTypeBestellung(pos: PosConfig): boolean
  {
    if (pos.postype.toString() === "3" && pos.kioskmode == false)
      return true;
    else
      return false;
  }
  
  _isTypeInfo(pos: PosConfig): boolean {
    if (pos.postype.toString() === "4")
      return true;
    else
      return false;
  }


  _isTypeGK(pos: PosConfig): boolean {
    if (pos.postype.toString() === "0")
      return true;
    else
      return false;
  }

  _isTypeEnergy(pos: PosConfig): boolean {
    if (pos.postype.toString() === "7")
      return true;
    else
      return false;
  }


  _isTypeLink(pos: PosConfig): boolean {
    if (pos.postype.toString() === "1")
      return true;
    else
      return false;
  }





  _getImageName(pos: PosConfig):string
  {
    return "../../assets/Images/" + pos.posName + ".jpg";
  }

  
  _getPOSName(pos: PosConfig) {
    //return pos.posName;
  }
  _getWelcomePageHeader(): string {
    return this.welcomePageHeader;

  }

  _getWelcomePageDiscription(): string {
    return this.welcomePageDiscription;

  }

  _getGaestekontoCardTitel(): string
  {
    return this.gaestekontoCardTitel;
  }

  _getHomepageCardTitel(): string {
    return this.homepageCardTitel;
  }


  navbestellung(pos: PosConfig) {
    this.posService.currentPOS = pos;
    this.router.navigate(["bestellung"]);
  }

  navgaestekonto(pos: PosConfig) {
    this.posService.currentPOS = pos;
    this.router.navigate(["gaestekonto"]);
  }

  navenergy(pos: PosConfig) {
    this.posService.currentPOS = pos;
    this.router.navigate(["energy"]);
  }


  navsmartcontrol(id: string) {
    console.log("navcontrol call with : " + id);

    this.router.navigate(["smartcontrol"]);

  }
  _changeLan(lang: string) {
    this.translate.use(lang);
    this._config.useLang(lang);
    this._setText();
  }

  navhome() {
    this.router.navigate(["login"]);
  }

  logout() {
    this.posService.Logout();
    this.router.navigate(["login"]);
  }

}
