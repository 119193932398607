import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';

import { LoginComponent } from './site_login/login.component';
import { BestellungComponent } from './site_bestellung/bestellung.component';
import { WelcomeComponent } from './site_welcome/welcome.component';
import { SmartControlComponent } from './site_smartcontrol/smartcontrol.component';
import { EnergyComponent } from './site_energy/energy.component';

import { NotificationService } from '../services/notification.service';
import { ToastrModule } from 'ngx-toastr';
import { PosService } from '../services/pos.services';
import { AdminComponent } from './site_admin_posconfig/admin_posconfig.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
// Imports the TextBox module
import { TextBoxModule } from '@progress/kendo-angular-inputs';
// Imports the CheckBox module
import { CheckBoxModule } from '@progress/kendo-angular-inputs';

import { GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs'
import { PosInternalComponent } from './site_pos_internal/pos_internal.component';
import { GeastekontoComponent } from './site_geastekonto/geastekonto.component';
import { GoodbyeComponent } from './site_goodbye/goodbye.component';
import { TouchKassaComponent } from './site_touch_kassa/touch_kassa.component';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppConfig } from './config/app.config';
import { TranslationConfigModule } from './translation//translation.config.module';

import { ConfigurationService } from '../services/configuration.service';
import { StorageService } from '../services/storage.service';

import { ChartsModule } from '@progress/kendo-angular-charts';
import { GaugesModule } from '@progress/kendo-angular-gauges';

import 'hammerjs';

 //Calling load to get configuration + translation
export function initResources(config: AppConfig, translate: TranslationConfigModule) {
  return () => config.load(translate);
}


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    BestellungComponent,
    WelcomeComponent,
    LoginComponent,
    AdminComponent,
    PosInternalComponent,
    GeastekontoComponent,
    GoodbyeComponent,
    TouchKassaComponent,
    SmartControlComponent,
    EnergyComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    HttpClientModule,
    FormsModule,
    PopupModule,
    BrowserAnimationsModule,
    TranslationConfigModule,
    ButtonsModule,
    LayoutModule,
    InputsModule,
    LabelModule,
    DialogModule,
    TextBoxModule,
    CheckBoxModule,
    GridModule,
    PDFModule,
    DateInputsModule,
    ChartsModule,
    GaugesModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot([
      //{ path: '', component: HomeComponent, pathMatch: 'full' },
      { path: '', component: LoginComponent, pathMatch: 'full' },

      //{ path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },

      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
      { path: 'login', component: LoginComponent },
      { path: 'welcome', component: WelcomeComponent },
      { path: 'bestellung', component: BestellungComponent },
      { path: 'gaestekonto', component: GeastekontoComponent },
      { path: 'goodbye', component: GoodbyeComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'posintern', component: PosInternalComponent },
      { path: 'touch', component: TouchKassaComponent },
      { path: 'smartcontrol', component: SmartControlComponent },
      { path: 'energy', component: EnergyComponent },
    ])
  ],
  providers: [
    NotificationService,
    PosService,
    ConfigurationService,
    StorageService,
    AppConfig, {
      provide: APP_INITIALIZER,
      useFactory: initResources,
      deps: [AppConfig, TranslationConfigModule],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

