import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from 'src/services/notification.service';
import { PosService } from 'src/services/pos.services';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { release } from 'process';

@Component({
  selector: 'app-home',
  templateUrl: './admin_posconfig.component.html',
})
export class AdminComponent {

  private hasSelectedPOSConfig: boolean;
  private posname: string;
  public PosConfigList: PosConfig[];
  //public loginLogs: LoginViewModel;
  public SelectedPOSConfig: PosConfig;
  public artikelListe: Artikel[];
  public selectableSettings: SelectableSettings;
  public errorReceived: boolean;
  private showInfo: boolean;
  public duration: number;
  public type: string; 
  public direction: string;
  public enabled: boolean;

  public _abholungStart: Date;
  public _abholungEnd: Date;
  public _min: Date;
  public _max: Date;
  public steps: any = { hour: 1, minute: 15 };

  public _fixOrderDate: Date;
  public _startOrderDate: Date;
  public _endOrderDate: Date;

  constructor(private router: Router, private notification: NotificationService, private posService: PosService) {
    this._Init();
    this.setPOSSettings();
    console.log(this.posService);

    if (!this.posService.login) {
      console.log("No Admin Login found - navigate to Login");
      this.router.navigate(["login"]);
    }

    if (!this.posService.login.isAdmin) {
      console.log("No Admin Login found - navigate to Login");
      this.router.navigate(["login"]);
    }
  }

  _Init() {
    //.....
    this.type = 'expand';
    this.duration = 500;
    this.direction = "right";
    this.enabled = true;
    //.....
    this.showInfo = false;
    this.PosConfigList = new Array<PosConfig>();
    this.hasSelectedPOSConfig = false;
    this.SelectedPOSConfig = null;
    this.errorReceived = false;
    this.setSelectableSettings();
    this.loadArtikel();
    this.loadPOSConfig();
    //this.loadLoginLogs();
    this.posname = "";

  }

  setPOSSettings() {
    if (this.SelectedPOSConfig) {
      if (!this.SelectedPOSConfig.intervall_size)
        return;

      if (this.SelectedPOSConfig.intervall_size.toString() === "0") // 15min default
        return;

      if (this.SelectedPOSConfig.intervall_size.toString() === "1") // 30min default
        this.steps = { hour: 1, minute: 30 };

      if (this.SelectedPOSConfig.intervall_size.toString() === "2") // 60min default
        this.steps = { hour: 1, minute: 60 };

    }
  }


  //********************* POP UP***********
  public onToggle(): void {
    this.showInfo = !this.showInfo;
  }

  public get animate(): any {
    if (this.enabled) {
      return {
        type: this.type,
        direction: this.direction,
        duration: this.duration
      };
    }

    return false;
  }

  public get hasDirection(): boolean {
    return this.type === 'slide' || this.type === 'expand';
  }

  //***************************************

  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onCreateNewPOS() {
    this.posname = "";
    this.hasSelectedPOSConfig = true;
    this.SelectedPOSConfig = {} as PosConfig;
    this.SelectedPOSConfig.artikel = new Array<Artikel>();
    this._abholungStart = new Date(2020, 1, 1, 0, 0, 0);
    this._abholungEnd = new Date(2020, 1, 1, 23, 45, 0);
    this._fixOrderDate = new Date();
    this._startOrderDate = new Date();
    this._endOrderDate = new Date();
    this.SelectedPOSConfig.showFooter = true;
    this.SelectedPOSConfig.showHeader = true;
    this.SelectedPOSConfig.aktiv = true;
  }

  onSelectPOS(pos: PosConfig) {
    this.SelectedPOSConfig = pos;

    this._abholungStart = new Date(this.SelectedPOSConfig.startTime);
    this._abholungEnd = new Date(this.SelectedPOSConfig.endTime);
    this._fixOrderDate = new Date(this.SelectedPOSConfig.fixOrderDate);

    this._startOrderDate = new Date(this.SelectedPOSConfig.startOrderDate);
    this._endOrderDate = new Date(this.SelectedPOSConfig.endOrderDate);

    this._min = new Date(this._abholungStart.getFullYear(), this._abholungStart.getMonth(), this._abholungStart.getDay(), 0, 0, 0);
    this._max = new Date(this._abholungEnd.getFullYear(), this._abholungEnd.getMonth(), this._abholungEnd.getDay(), 23, 45, 0);

    //check Item is allready in POS-List
    if (!this.SelectedPOSConfig.artikel)
      this.SelectedPOSConfig.artikel = new Array<Artikel>();

    console.log("pos with Id " + this.SelectedPOSConfig.posGuid + " has been selected.");

    if (this.SelectedPOSConfig.artikel)
      this.SelectedPOSConfig.artikel.sort((a, b) => (a.orderId > b.orderId) ? 1 : ((b.orderId > a.orderId) ? -1 : 0));

    this.hasSelectedPOSConfig = true;
    this.posname = this.SelectedPOSConfig.posName;
  }

  onRemoveArtikel(a: Artikel) {
    if (confirm("Artikel " + a.bezeichnung + "aus der POSConfig löschen ?")) {
      const index = this.SelectedPOSConfig.artikel.indexOf(a, 0);
      if (index > -1) {
        this.SelectedPOSConfig.artikel.splice(index, 1);
      }
    }
  }

  selectedKeysChange(selectedItem: any) {

    const item1 = this.artikelListe.find(i => i.artikelnummer === selectedItem[0]);


    const found = this.SelectedPOSConfig.artikel.find(i => i.artikelnummer === item1.artikelnummer);
    if (!found) {
      item1.groupName = "Default";
      item1.orderId = this.SelectedPOSConfig.artikel.length + 1;
      this.SelectedPOSConfig.artikel.push(item1);
      //this.notification.success("Artikel" + item1.bezeichnung + "zur POS-Config hinzugefügt");
      this.loadArtikel();
    }
    else {
      //this.notification.warning("Artikel ist bereits in der POS-Config in Verwendung");
    }
  }

  _PosUseArtikel(pos: PosConfig): boolean
  {
    if (!pos.postype)
      return true;

    if (pos.postype.toString() === "0") //GK
      return false;
    if (pos.postype.toString() === "1") //link
      return false;
    if (pos.postype.toString() === "2") //intern
      return true;
    if (pos.postype.toString() === "3")  //public
      return true;
    if (pos.postype.toString() === "4")  //info
      return false;

    //fallback
    return true;
  }



  loadArtikel() {
    this.posService.getProducts()
      .subscribe((res: Artikel[]) => {
        this.artikelListe = res;
        //console.log("artikelListe ->");
        //console.log(this.artikelListe);
      });
  }

  loadPOSConfig() {
    this.posService.getAllPos()
      .subscribe((res: PosConfig[]) => {
        this.PosConfigList = res;
        console.log("PosConfigList ->");
        console.log(this.PosConfigList);
      });
  }

  _getPosTyp(pos: PosConfig): string
  {
    if (pos)
    {
      if (!pos.postype)
        return "Unbekannt";

      if (pos.postype.toString() === "0")
        return "GK";

      if (pos.postype.toString() === "1")
        return "link";

      if (pos.postype.toString() === "2")
        return "intern";

      if (pos.postype.toString() === "3")
        return "public";

      if (pos.postype.toString() === "4")
        return "info";

      if (pos.postype.toString() === "5")
        return "iot";

      if (pos.postype.toString() === "6")
        return "rental";

      if (pos.postype.toString() === "7")
        return "energy";

        //gaestekonto = 0,
        //weblink = 1,
        //posintern = 2,
        //pospublic = 3,
        //info = 4,
        //iot = 5,
        //rental = 6,
        //energy = 7



    }
    return "??" + pos.postype;
  }


  savePOSConfig() {

    if (this.hasSelectedPOSConfig) {

      this.SelectedPOSConfig.startTime = this._abholungStart;
      this.SelectedPOSConfig.endTime = this._abholungEnd;
      this.SelectedPOSConfig.fixOrderDate = this._fixOrderDate;

      this.SelectedPOSConfig.startOrderDate = this._startOrderDate;
      this.SelectedPOSConfig.endOrderDate = this._endOrderDate;
      
      //Validation
      if (!this.SelectedPOSConfig.posName) {
        this.notification.error("Kein POS Name gesetzt.  Daten nicht gespeichert !");
        return;
      } else {
        const posfound = this.PosConfigList.find(i => i.posName.toLowerCase() === this.SelectedPOSConfig.posName.toLowerCase());
        if (posfound) {

          if (posfound.posGuid !== this.SelectedPOSConfig.posGuid) {
            this.notification.error("POS Name schon in verwendung.  Daten nicht gespeichert !");
            return;
          }
        }
      }

      this.notification.warning("POS-Config wird gespeichert, Synchronisation der Artikelstammdaten aus SmartCamp erfolgt. Bitte Warten ... ");

      console.log('Save ->');
      this.posService.savePosConfig(this.SelectedPOSConfig)
        .subscribe((res: any) => {

          const result = res as BaseResult;

          if (result.success) {
            this.notification.success(result.successMessage);
            this.hasSelectedPOSConfig = false;
            this.SelectedPOSConfig = null;

            this.loadPOSConfig();
            this.posname = "";

          } else {
            this.notification.error(result.errorMessage);
          }
        });
    }
  }

  closePOSConfig()
  {
    this.hasSelectedPOSConfig = false;
    this.SelectedPOSConfig = null;
    this.loadPOSConfig();
    this.posname = "";
  }

  deletePOSConfig() {

    if (!this.SelectedPOSConfig.posGuid) {
      this.notification.warning("POS-Config kann nicht gelöscht werden - Es wurde noch nicht gespeichert");
      return;
    }

    if (this.hasSelectedPOSConfig)
    {
      if (confirm("Soll das POS " + this.SelectedPOSConfig.posName + " gelöscht werden ?")) {

        this.posService.deletePosConfig(this.SelectedPOSConfig)
          .subscribe((res: any) => {

            const result = res as BaseResult;

            if (result.success) {
              //this.notification.success(result.successMessage);
              this.hasSelectedPOSConfig = false;
              this.SelectedPOSConfig = null;
              //Reload Bestellungen
              this.loadPOSConfig();
              this.posname = "";

            } else {
              //this.notification.error(result.errorMessage);
            }

          });
      }
    }
  }



  //************ Helper ****************************************
  private handleError(error: any) {

    if (error.error instanceof ErrorEvent) {
      // client-side error
      //this.notification.error(`client-side Error Code: ${error.error.message}`);

    } else {
      // server-side error
      //this.notification.error(`server-side Error Code: ${error.status}\nMessage: ${error.message}`);

    }
    this.errorReceived = true;
    return Observable.throw(error);
  }

  navhome() {
    this.router.navigate(["login"]);
  }

  logout() {
    this.posService.Logout();
    this.router.navigate(["login"]);
  }

  //************ Helper ****************************************


}
