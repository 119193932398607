import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from 'src/services/notification.service';
import { PosService } from 'src/services/pos.services';
import { ToastrService } from 'ngx-toastr';
import { param, error } from 'jquery';
import { interval, Subscription } from 'rxjs';
import { AppConfig } from 'src/app/config/app.config';
import { collectExternalReferences } from '@angular/compiler';

@Component({
  selector: 'app-home',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  private loginReq: LoginRequestModel;
  private loginCode: string;
  private loginsubscription: Subscription;
  private ptype: string;
  private ptext: string;
  private pstate: boolean;
  private posConfigList: PosConfig[];
 
  constructor(private _config: AppConfig, private router: Router, private activatedRoute: ActivatedRoute, private notification: NotificationService, private posService: PosService, public toastr: ToastrService) {

    this.pstate = true;
    this._switchPasswordType();
    

    this._Init();
    this._loadPOS();
    

    if (this.posService.login && this.posService.login.pos) {
      console.log("Valid xx POS Login found - navigate to welcome");
      this.router.navigate(["posintern"]);
    }else if (this.posService.validLogin)
    {
      console.log("Valid Login found - navigate to welcome");
      this.router.navigate(["welcome"]);
    }

  }

  _Init()
  {
    this.loginCode = "";//"HK-301189";//414
    this.loginReq = {} as LoginRequestModel;
    this.posConfigList = new Array<PosConfig>();
  }
  _switchPasswordType()
  {
    
    if (this.pstate) {
      this.ptype = "password";
      this.ptext = this._config.getResourceByKey('showPassword');
      this.pstate = !this.pstate;
    } else
    {
      this.ptype = "text";
      this.ptext = this._config.getResourceByKey('hidePassword');
      this.pstate = !this.pstate;
    }

  }

  _getPasswordDescription():string
  {
    return this.ptext;
  }


  ngOnInit() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.activatedRoute.queryParams.subscribe(params => {
      this.loginCode = params['idx'];
      this.posService.readerID = params['readerID'];
      this.posService.autonavigate = params['autonavigate'];
      this.posService.onlyautologin = this._getBoolean(params['onlyautologin']);
      this.posService.autoLoginPosName = params['autoLoginPosName'];

      if (this.posService.onlyautologin)
      {
        console.log('ping');
        console.log(this.posService.autoLoginPosName);
        this._startautologin();
        //const source = interval(5000);
        //this.loginsubscription = source.subscribe(val => this._startautologin());
        //this._startautologin();
      }
      else if (this.loginCode) {
        console.log("Set Login Code From URL : " + this.loginCode);
        this._login();
      } 
    });
    
  }

  ngOnDestroy() {
   
    //if (this.loginsubscription)
    //  this.loginsubscription.unsubscribe();
  }

  _loadPOS() {
    this.posService.getVisibelPos()
      .subscribe((res: PosConfig[]) => {
        //console.log(res);
        this.posConfigList = res;
        this._setAutoPOS();
      });
  }

  _setAutoPOS()
  {

    if (this.posService.autoLoginPosName) {
      console.log('AUTO POS should set to ' + this.posService.autoLoginPosName);
      let result = this.posConfigList.find(i => i.posName === this.posService.autoLoginPosName);

      console.log(result);

      if (result) {
        console.log('AUTO POS set to ' + result);
        this.posService.currentPOS = result;
      }
    }
  }


  _startautologin() {
    //console.log("start reading for rfid login...");

    this.loginReq.ReaderID = this.posService.readerID;
    this.loginReq.ReaderLogin = true;

    this.posService.rfidLogin(this.loginReq)
      .subscribe((res : any) => {

        //res = response as LoginRequestModel;

        if (res.success) {
          this.posService.login = res;
          this.notification.success(res.successMessage);
          this.posService.validLogin = true;
          this.router.navigate([this.posService.autonavigate]); // e.g. "gaestekonto","smartcontrol"
        } else
        {

          if ("RFID - Request Timeout" != res.errorMessage)
            this.notification.error(res.errorMessage);

          this._startautologin();
        }
      },
        (error) =>
        {
          console.log(error);
          this._delay(5000);
          this._startautologin();
        }
      );
  }

  _delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }


  _login()
  {
    
    if (this.posService.validLogin === true) {
      this.notification.success("Login gefunden.");
    } else {

      this.loginReq.UserID = this.loginCode;

      this.posService.Login(this.loginReq)
        .subscribe((res: any) => {

            if (res.success) {

              this.posService.login = res;
              this.notification.success(res.successMessage);
              this.posService.validLogin = true;

              if (this.posService.login.isAdmin) {
                this.posService.validLogin = false;
                this.router.navigate(["admin"]);

              } else if (this.posService.login.pos)
                {
                this.router.navigate(["posintern"]);
                }
              else {
                this.router.navigate(["welcome"]);
              }
            } else {
              this.posService.validLogin = false;
              this.notification.error(res.errorMessage);
            }
        });
    }
  }

  _getBoolean(value) {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}

}
