import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PosService } from 'src/services/pos.services';
import { NotificationService } from 'src/services/notification.service';
import { interval, Subscription } from 'rxjs';
import { decode } from 'punycode';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/config/app.config';

@Component({
  selector: 'app-home',
  templateUrl: './smartcontrol.component.html',
})
export class SmartControlComponent {

  private DisplayGastData: string;
  private currentDevice: Device;
  private currentReader: ReaderViewModel;
  private login: LoginRequestModel;
  private showInfo: boolean;
  private showGoodbye: boolean;

  private subscription: Subscription;
  private sub1: Subscription;
  private sub2: Subscription;
  private sub3: Subscription;
  private sub4: Subscription;
  private sub5: Subscription;
  public myBasket: Basket;
  private txrunning: boolean;

  private SmartControlHeader: string;
  private SmartControlDescription: string;
  private ButtonOccupied: string;
  private ButtonFree: string;
  private ButtonOffline: string;
  private SmartControlDescriptionWashing: string;
  private SmartControlDescriptionDryer: string;
  private SmartControlDescriptionDishwasher: string;
  private SmartControlDescriptionDisposer: string;


  //.................
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  //.................


  constructor(private router: Router, private posService: PosService, private notification: NotificationService, private idle: Idle, private keepalive: Keepalive, private _config: AppConfig, public translate: TranslateService) {

    this.init();
    this.idlefunction(60);
    this.setText();
    if (!posService.validLogin) {
      console.log("no valid Login found -> navigate to logon page");
      this.router.navigate(["login"]);
    }
  }

  init() {
    this.showInfo = true;
    this.currentDevice = {} as Device;
    this.currentReader = {} as ReaderViewModel;
    this.login = {} as LoginRequestModel;
    this.txrunning = false;
    const source = interval(5000);

    this.getReaderInfo();

    this.subscription = source.subscribe(val => this.updateState());

    this.myBasket = {} as Basket;
    this.myBasket.artikel = new Array<Artikel>();

  }

  setText() {
    const SmartControlHeader_key = 'SmartControl.SmartControlHeader';
    this.translate.get(SmartControlHeader_key).subscribe((res) => {

      if (res === SmartControlHeader_key)
        this.SmartControlHeader = "Kein " + SmartControlHeader_key + " Text gefunden";
      else
        this.SmartControlHeader = res;
    });

    const SmartControlDescription_key = 'SmartControl.SmartControlDescription';
    this.translate.get(SmartControlDescription_key).subscribe((res) => {

      if (res === SmartControlDescription_key)
        this.SmartControlDescription = "Kein " + SmartControlDescription_key + " Text gefunden";
      else
        this.SmartControlDescription = res;
    });

    const ButtonOccupied_key = 'SmartControl.ButtonOccupied';
    this.translate.get(ButtonOccupied_key).subscribe((res) => {

      if (res === ButtonOccupied_key)
        this.ButtonOccupied = "Kein " + ButtonOccupied_key + " Text gefunden";
      else
        this.ButtonOccupied = res;
    });

    const ButtonFree_key = 'SmartControl.ButtonFree';
    this.translate.get(ButtonFree_key).subscribe((res) => {

      if (res === ButtonFree_key)
        this.ButtonFree = "Kein " + ButtonFree_key + " Text gefunden";
      else
        this.ButtonFree = res;
    });

    const ButtonOffline_key = 'SmartControl.ButtonOffline';
    this.translate.get(ButtonOffline_key).subscribe((res) => {

      if (res === ButtonOffline_key)
        this.ButtonOffline = "Kein " + ButtonOffline_key + " Text gefunden";
      else
        this.ButtonOffline = res;
    });

    const SmartControlDescriptionWashing_key = 'SmartControl.SmartControlDescriptionWashing';
    this.translate.get(SmartControlDescriptionWashing_key).subscribe((res) => {

      if (res === SmartControlDescriptionWashing_key)
        this.SmartControlDescriptionWashing = "Kein " + SmartControlDescriptionWashing_key + " Text gefunden";
      else
        this.SmartControlDescriptionWashing = res;
    });

    const SmartControlDescriptionDryer_key = 'SmartControl.SmartControlDescriptionDryer';
    this.translate.get(SmartControlDescriptionDryer_key).subscribe((res) => {

      if (res === SmartControlDescriptionDryer_key)
        this.SmartControlDescriptionDryer = "Kein " + SmartControlDescriptionDryer_key + " Text gefunden";
      else
        this.SmartControlDescriptionDryer = res;
    });

    const SmartControlDescriptionDishwasher_key = 'SmartControl.SmartControlDescriptionDishwasher';
    this.translate.get(SmartControlDescriptionDishwasher_key).subscribe((res) => {

      if (res === SmartControlDescriptionDishwasher_key)
        this.SmartControlDescriptionDishwasher = "Kein " + SmartControlDescriptionDishwasher_key + " Text gefunden";
      else
        this.SmartControlDescriptionDishwasher = res;
    });

    const SmartControlDescriptionDisposer_key = 'SmartControl.SmartControlDescriptionDisposer';
    this.translate.get(SmartControlDescriptionDisposer_key).subscribe((res) => {

      if (res === SmartControlDescriptionDisposer_key)
        this.SmartControlDescriptionDisposer = "Kein " + SmartControlDescriptionDisposer_key + " Text gefunden";
      else
        this.SmartControlDescriptionDisposer = res;
    });
  }

  _getSmartControlHeader(): string { return this.SmartControlHeader; }
  _getSmartControlDescription(): string { return this.SmartControlDescription; }
  _getButtonOccupied(): string { return this.ButtonOccupied; }
  _getButtonFree(): string { return this.ButtonFree; }
  _getButtonOffline(): string { return this.ButtonOffline; }
  _getSmartControlDescriptionWashing(): string { return this.SmartControlDescriptionWashing; }
  _getSmartControlDescriptionDryer(): string { return this.SmartControlDescriptionDryer; }
  _getSmartControlDescriptionDishwasher(): string { return this.SmartControlDescriptionDishwasher; }
  _getSmartControlDescriptionDisposer(): string { return this.SmartControlDescriptionDisposer; }





  _changeLan(lang: string) {
    this.translate.use(lang);
    this._config.useLang(lang);
    this.setText();
  }

  idlefunction(idelseconds: number) {

    if (this.sub1)
      this.sub1.unsubscribe();
    if (this.sub2)
      this.sub2.unsubscribe();
    if (this.sub3)
      this.sub3.unsubscribe();
    if (this.sub4)
      this.sub4.unsubscribe();
    if (this.sub5)
      this.sub5.unsubscribe();


    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(idelseconds);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.sub1 = this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    this.sub2 = this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      //this.router.navigate(['/']);
      this.logout();
    });

    this.sub3 = this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);

      //this.childModal.show();

    });

    this.sub4 = this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      //this.notification.warning(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.sub5 = this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }


  ngOnDestroy() {
    //console.log(">> ngOnDestroy");
    this.subscription.unsubscribe();
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
    this.sub3.unsubscribe();
    this.sub4.unsubscribe();
    this.sub5.unsubscribe();
    //console.log("<< ngOnDestroy");
  }


  _changeShowInfo() {
    if (this.showInfo)
      this.showInfo = false;
    else {
      this.currentDevice = {} as Device;
      this.showInfo = true;
    }
  }

  getReaderInfo() {

    this.login.ReaderLogin = true;
    this.login.ReaderID = this.posService.readerID;

    this.posService.getReaderInfo(this.login)
      .subscribe((res: any) => {
        this.currentReader = res;
        //console.log(this.currentReader);
      });
  }

  updateState() {
    //console.log(">> update State");
    this.posService.getReaderInfo(this.login)
      .subscribe((res: any) => {
        //console.log("<< update State");
        //console.log(res);
        this.updateCurrentReader(res);
        //this.currentReader = res;
      });


  }
  updateCurrentReader(update: ReaderViewModel) {
    for (const item of update.devices) {
      for (const current of this.currentReader.devices) {

        if (current.name === item.name) {
          current.state = item.state;
          current.programmstate = item.programmstate;
        }
      }
    }
  }

  _isProgrammDevice(device: Device) {
    if (device.typ.toString() === "0")
      return true;
    else
      return false;
  }


  _selectDevice(device: Device) {
    this.currentDevice = device;
    this._changeShowInfo();
  }


  _buyDevice(device: Device) {

    if (this.txrunning)
      return;

    if (device.typ.toString() === "0") {
      if (!this._deviceStateOn(device)) {
        this.notification.error(device.name + " ist nicht betriebsbereit. Device is not operational.");
        return;
      }
    }

    if (this._deviceProgrammStateBusy(device)) {
      this.notification.error(device.name + " ist belegt. Device is busy.");
      return;
    }


    console.log("Buy Device");
    console.log(device);
    this.txrunning = true;


    this.posService.buyDevice(device)
      .subscribe((res) => {
        console.log(res);
        this.notification.success(device.name + " wurde freigeschalten.");

        //TODO : TX abfrage ob ok !

        this.myBasket.kassa = false;
        this.myBasket.gaestekonto = true;
        this.myBasket.gkid = this.posService.login.gkid;
        this.sendBasket(device);
      });
  }


  sendBasket(device: Device) {

    const artikel = {} as Artikel;

    artikel.artikelnummer = device.pmsartikelnummer;
    artikel.anzahl = 1;
    this.myBasket.artikel.push(artikel);

    this.posService.sendBasket(this.myBasket)
      .subscribe((res: any) => {

        if (res.success) {
          this.notification.success(res.successMessage);
          this.showGoodbye = true;
          this.idlefunction(10);
        } else {
          this.notification.error(res.errorMessage);
        }

        this.txrunning = false;

      });
  }





  ////////////////////////////////////////
  //{ belegt, frei, unbekannt }

  _deviceProgrammStateFree(device: Device) {
    //Status ist Off und es ist ein Programm Device (aktives State Signal)
    if (this._deviceStateOff(device) && device.typ.toString() === "0")
      return false;

    if (device.programmstate.toString() === "1")
      return true;
    else
      return false;
  }

  _deviceProgrammStateBusy(device: Device) {
    //Status ist Off und es ist ein Programm Device (aktives State Signal)
    if (this._deviceStateOff(device) && device.typ.toString() === "0")
      return false;


    if (device.programmstate.toString() === "0")
      return true;
    else
      return false;
  }
  ////////////////////////////////////////


  //{ ok , off, unbekannt }
  _deviceStateUnknown(device: Device) {
    //console.log(device);
    if (device.state.toString() === "2")
      return true;
    else
      return false;
  }

  _deviceStateOff(device: Device) {
    //console.log(device);
    if (device.state.toString() === "1")
      return true;
    else
      return false;
  }

  _deviceStateOn(device: Device) {
    //console.log(device);
    if (device.state.toString() === "0")
      return true;
    else
      return false;
  }


  //{  washing,    dryer,    dishwasher,    disposer}

  _isWaschmaschine(device: Device) {
    if (device.unittyp.toString() === "0")
      return true;
    else
      return false;
  }

  _isTrockner(device: Device) {
    if (device.unittyp.toString() === "1")
      return true;
    else
      return false;
  }

  _isSpueler(device: Device) {
    if (device.unittyp.toString() === "2")
      return true;
    else
      return false;
  }

  _isCampingCleaner(device: Device) {
    if (device.unittyp.toString() === "3")
      return true;
    else
      return false;
  }

  // ************************ GATE
  openGate() {
    console.log("onClick_openGate");

    this.posService.openGate()
      .subscribe((res) => {
        console.log(res);
      });


  }

  closeGate() {

    console.log("onClick_CloseGate");

    this.posService.closeGate()
      .subscribe((res) => {
        console.log(res);
      });

  }
  // ************************ GATE

  //buyDevice(device: string)
  //{

  //  this.currentDevice.name = device;
  //  this.currentDevice.relaisNr = 1;
  //  this.currentDevice.baseurl = "http://192.168.99.69:5000";


  //  this.posService.buyDevice(this.currentDevice)
  //    .subscribe((res) => {
  //      console.log(res);
  //    });
  //}



  navhome() {
    this.posService.Logout();
    this.router.navigate(["login"], { queryParams: { readerID: this.posService.readerID, autonavigate: this.posService.autonavigate, onlyautologin: this.posService.onlyautologin }, queryParamsHandling: "merge" });
  }

  logout() {
    this.posService.Logout();
    this.router.navigate(["login"], { queryParams: { readerID: this.posService.readerID, autonavigate: this.posService.autonavigate, onlyautologin: this.posService.onlyautologin }, queryParamsHandling: "merge" });
  }

}
