import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PosService } from 'src/services/pos.services';
import { NotificationService } from 'src/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/config/app.config';
import { ConfigurationService } from 'src/services/configuration.service';

declare function pos_pay_test(): any;

@Component({
  selector: 'app-home',
  templateUrl: './geastekonto.component.html',
})
export class GeastekontoComponent {
  public myGaestekonto: GaestekontoViewModel;
  public myPament: PaymentViewModel;
  public myContent: ContentViewModel;
  public blob: Blob;
  public checkPay: boolean;
  public myPosConfig: PosConfig;

  private Header: string;
  private Description: string;
  private Date: string;
  private Pitch: string;
  private Arrival: string;
  private Departure: string;
  private Days: string;
  private Subtotal: string;
  private Deposit: string;
  private Invoiceamount: string;
  private PaymentDescription: string;
  private ButtonPay: string;
  private OpenInvocie: string;
  public opened = false;

  private _startValue: string;
  private _currentValue: string;
  private _StromAktiv: boolean;

  constructor(private router: Router, private posService: PosService, private notification: NotificationService, private _config: AppConfig, public translate: TranslateService, private configurationService: ConfigurationService) {

    if (!posService.validLogin) {
      console.log("no valid Login found -> navigate to logon page");
      this.router.navigate(["login"]);
    }
    this.init();
    this.loadGK();
    this.setText();
  }

  init() {
   
    this.myGaestekonto = {} as GaestekontoViewModel;
    this.checkPay = false;
    this.myPosConfig = this.posService.currentPOS;
  }

  //***************************************
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }
  //***************************************

  setText() {

    const Header_key = 'Gästekonto.Header';
    this.translate.get(Header_key).subscribe((res) => {

      if (res === Header_key)
        this.Header = "Kein " + Header_key + " Text gefunden";
      else
        if (res.indexOf('#STATE#') >= 0) {
          if (this.posService.login) {
            //TODO Status translate
            this.Header = res.replace('#STATE#', this.posService.login.status);
          }
          else
            this.Header = res;

        } else {

          this.Header = res;
        }
    });

    const Description_key = 'Gästekonto.Description';
    this.translate.get(Description_key).subscribe((res) => {

      if (res === Description_key)
        this.Description = "Kein " + Description_key + " Text gefunden";
      else
        this.Description = res;
    });

    const Date_key = 'Gästekonto.Date';
    this.translate.get(Date_key).subscribe((res) => {

      if (res === Date_key)
        this.Date = "Kein " + Date_key + " Text gefunden";
      else
        this.Date = res;
    });

    const Pitch_key = 'Gästekonto.Pitch';
    this.translate.get(Pitch_key).subscribe((res) => {

      if (res === Pitch_key)
        this.Pitch = "Kein " + Pitch_key + " Text gefunden";
      else
        this.Pitch = res;
    });

    const Arrival_key = 'Gästekonto.Arrival';
    this.translate.get(Arrival_key).subscribe((res) => {

      if (res === Arrival_key)
        this.Arrival = "Kein " + Arrival_key + " Text gefunden";
      else
        this.Arrival = res;
    });

    const Departure_key = 'Gästekonto.Departure';
    this.translate.get(Departure_key).subscribe((res) => {

      if (res === Departure_key)
        this.Departure = "Kein " + Departure_key + " Text gefunden";
      else
        this.Departure = res;
    });

    const Days_key = 'Gästekonto.Days';
    this.translate.get(Days_key).subscribe((res) => {

      if (res === Days_key)
        this.Days = "Kein " + Days_key + " Text gefunden";
      else
        this.Days = res;
    });

    const Subtotal_key = 'Gästekonto.Subtotal';
    this.translate.get(Subtotal_key).subscribe((res) => {

      if (res === Subtotal_key)
        this.Subtotal = "Kein " + Subtotal_key + " Text gefunden";
      else
        this.Subtotal = res;
    });

    const Deposit_key = 'Gästekonto.Deposit';
    this.translate.get(Deposit_key).subscribe((res) => {

      if (res === Deposit_key)
        this.Deposit = "Kein " + Deposit_key + " Text gefunden";
      else
        this.Deposit = res;
    });

    const Invoiceamount_key = 'Gästekonto.Invoiceamount';
    this.translate.get(Invoiceamount_key).subscribe((res) => {

      if (res === Invoiceamount_key)
        this.Invoiceamount = "Kein " + Invoiceamount_key + " Text gefunden";
      else
        this.Invoiceamount = res;
    });

    const PaymentDescription_key = 'Gästekonto.PaymentDescription';
    this.translate.get(PaymentDescription_key).subscribe((res) => {

      if (res === PaymentDescription_key)
        this.PaymentDescription = "Kein " + PaymentDescription_key + " Text gefunden";
      else
        this.PaymentDescription = res;
    });

    const ButtonPay_key = 'Gästekonto.ButtonPay';
    this.translate.get(ButtonPay_key).subscribe((res) => {

      if (res === ButtonPay_key)
        this.ButtonPay = "Kein " + ButtonPay_key + " Text gefunden";
      else
        this.ButtonPay = res;
    });

    const OpenInvocie_key = 'Gästekonto.OpenInvocie';
    this.translate.get(OpenInvocie_key).subscribe((res) => {

      if (res === OpenInvocie_key)
        this.OpenInvocie = "Kein " + OpenInvocie_key + " Text gefunden";
      else
        this.OpenInvocie = res;
    });
  }

  _getHeader(): string { return this.Header };
  _getDescription(): string { return this.Description };
  _getDate(): string { return this.Date };
  _getPitch(): string { return this.Pitch };
  _getArrival(): string { return this.Arrival };
  _getDeparture(): string { return this.Departure };
  _getDays(): string { return this.Days };
  _getSubtotal(): string { return this.Subtotal };
  _getDeposit(): string { return this.Deposit };
  _getInvoiceamount(): string { return this.Invoiceamount };
  _getPaymentDescription(): string { return this.PaymentDescription };
  _getButtonPay(): string { return this.ButtonPay };
  _getOpenInvocie(): string { return this.OpenInvocie };


  _changeLan(lang: string) {
    this.translate.use(lang);
    this._config.useLang(lang);
    this.setText();
  }


 



  loadGK() {
    if (this.posService.login) {



      const tmpRequest = {} as RequestGaestekontoViewModel;
      tmpRequest.gkid = this.posService.login.gkid;

      this.posService.getGeastekonto(tmpRequest)
        .subscribe((res: any) => {
          this.myGaestekonto = res;
          console.log(res);
          this._startValue = this.myGaestekonto.startMeterValue;
          this._StromAktiv = this.myGaestekonto.stromAktiv;

          if (this._StromAktiv && this.myPosConfig.setKWHArtikel) // && POS Config bool Set to True
          {

            this.posService.getSmartEnergy(this.myGaestekonto.smartEChannel)
              .subscribe((res: any) => {
                this._currentValue = res.Total_activeenergy.toString();


                const request = {} as RequestGaestekontoViewModel;
                const setrequest = {} as RequestKWHArtikelViewModel;
                request.gkid = this.posService.login.gkid;
                setrequest.gkid = this.posService.login.gkid;
                setrequest.startValue = this._startValue;
                setrequest.currentValue = this._currentValue;

                this.posService.setKWHArtikel(setrequest)
                  .subscribe((setres: any) => {

                    const result = setres as BaseResult;

                    if (result.success) {

                      this.notification.success(result.successMessage);
                      this.posService.getGeastekonto(request)

                        .subscribe((res: any) => {
                          this.myGaestekonto = res;
                        });
                    } else {
                      this.notification.error(result.errorMessage);
                    }
                  });
              });
          }
        });
    }
  }

  public showOnlyZusatztextDetails(dataItem: any, index: number): boolean {

    return dataItem.hasZusatztext === true;
  }
  //public showOnlyhasBuchungslogDetails(dataItem: any, index: number): boolean {
  //  console.log("yy");
  //  return dataItem.hasBuchungslog === true;
  //}



  navhome() {
    this.router.navigate(["login"]);
  }

  logout() {
    this.posService.Logout();
    this.router.navigate(["login"]);
  }


  payment() {
    if (this.posService.login) {
      const request = {} as RequestGaestekontoViewModel;
      request.gkid = this.posService.login.gkid;

      this.posService.getPayment(request)
        .subscribe((res: any) => {
          this.myPament = res;
          //console.log('*** getPayment **');
          //console.log(res);
          //console.log('*****************');

          this.loadPayment(this.myPament.id);

          

          this.opened = true;
        });
    }
  }




  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }



  loadPayment(id: string) {

    const url = this.configurationService.serverSettings.oppurl + id;  //this.posService.oppurl + id;

    //console.log('preparing to load...')
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
    console.log('****** loadPayment *****');
    console.log(node);
    console.log('************************');
  }

  clearDetailInfo() {
    this.myPament = {} as PaymentViewModel;
  }


  openBill() {
    const request = {} as RequestGaestekontoViewModel;
    request.gkid = this.posService.login.gkid;

    this.posService.getPDF(request)
      .subscribe((res: any) => {
        //console.log('******');
        //console.log(res);
        //console.log('******');
        //this.myContent = res;this.myContent.bytes
        //console.log(res.bytes);

        this.HandleBase64(res.bytes, "application/pdf", res.fileName);

        return;

      });

  }

  HandleBase64(data, contentType, fileName) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++)
      byteNumbers[i] = byteCharacters.charCodeAt(i);

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    if (contentType === "audio/wav") {
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }
    else {
      const blobURL = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.click();
    }
  }





}
